import React, { useEffect } from "react";
import { homeService } from "../../api/services/homeService";
import { FAQItem } from "../../utils/type";
import HtmlParser from "react-html-parser";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLanguageStore } from "../../zustand/store/language";
import { Helmet } from "react-helmet";

function FAQ() {
  const [faqData, setFaqData] = React.useState<FAQItem[]>([]);
  const { language } = useLanguageStore();

  const getFaq = async () => {
    try {
      const response = await homeService.getFaq();
      if (response && response.status === 200) {
        setFaqData(response.data);
      } else {
        console.log(response.data, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFaq();
  }, [language]);

  return (
    <div style={{ minHeight: "100vh", padding: "20px" }}>
      {/* <Helmet>
        <title>FAQ</title>
      </Helmet> */}

      <Container maxWidth={"lg"}>
        {faqData && faqData.length > 0 && (
          <Typography sx={{ fontSize: "20px", fontWeight: "700", mb: 2 }}>
            Frequent Quetions
          </Typography>
        )}
        {faqData.map((faq) => (
          <Accordion key={faq.id}>
            <AccordionSummary
              sx={{
                marginY: "10px",
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${faq.id}-content`}
              id={`panel${faq.id}-header`}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                {faq.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {HtmlParser(faq.description)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </div>
  );
}

export default FAQ;
