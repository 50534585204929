import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  Typography,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
}

const UpdatePasswordDialog: React.FC<CustomDialogProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();

  const initialValues = {
    current_password: "",
    password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object().shape({
    current_password: Yup.string()
      .required(t("errors.current_password.required"))
      .min(6, t("errors.current_password.min")),
    password: Yup.string()
      .required(t("errors.new_password.required"))
      .min(6, t("errors.new_password.min")),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password")], t("errors.confirm_password.match"))
      .required(t("errors.confirm_password.required")),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "24px 24px 0px 24px !important" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
            {t("userInfo.update_password")}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({
          errors,
          touched,
          handleSubmit,
          values,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            <DialogContent sx={{ padding: "0px 24px 24px 24px !important" }}>
              <Field
                name="current_password"
                as={TextField}
                label={t("userInfo.current_password")}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                fullWidth
                margin="normal"
                value={values.current_password}
                onChange={handleChange}
                error={touched.current_password && !!errors.current_password}
                helperText={touched.current_password && errors.current_password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibility}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  borderRadius: "4px",
                  "& label": {
                    transformOrigin:
                      direction === "rtl" ? "right !important" : "unset",
                    left: direction === "rtl" ? "inherit !important" : "unset",
                    right: direction === "rtl" ? "1.75rem !important" : "unset",
                    fontSize: "small",
                    color: "#000000",
                    fontWeight: 400,
                    overflow: "unset",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "small",
                    color: "#000000",
                    fontWeight: 400,
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    fontWeight: 400,
                    lineHeight: "17.34px",
                    "& legend": {
                      textAlign: direction === "rtl" ? "right" : "left",
                    },
                  },
                }}
              />
              <Field
                name="password"
                as={TextField}
                label={t("userInfo.new_password")}
                variant="outlined"
                fullWidth
                type={showNewPassword ? "text" : "password"}
                margin="normal"
                value={values.password}
                onChange={handleChange}
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleNewPasswordVisibility}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  borderRadius: "4px",
                  "& label": {
                    transformOrigin:
                      direction === "rtl" ? "right !important" : "unset",
                    left: direction === "rtl" ? "inherit !important" : "unset",
                    right: direction === "rtl" ? "1.75rem !important" : "unset",
                    fontSize: "small",
                    color: "#000000",
                    fontWeight: 400,
                    overflow: "unset",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "small",
                    color: "#000000",
                    fontWeight: 400,
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    fontWeight: 400,
                    lineHeight: "17.34px",
                    "& legend": {
                      textAlign: direction === "rtl" ? "right" : "left",
                    },
                  },
                }}
              />
              <Field
                name="confirm_password"
                as={TextField}
                label={t("userInfo.confirm_password")}
                variant="outlined"
                fullWidth
                margin="normal"
                value={values.confirm_password}
                onChange={handleChange}
                error={touched.confirm_password && !!errors.confirm_password}
                helperText={touched.confirm_password && errors.confirm_password}
                type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleConfirmPasswordVisibility}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  borderRadius: "4px",
                  "& label": {
                    transformOrigin:
                      direction === "rtl" ? "right !important" : "unset",
                    left: direction === "rtl" ? "inherit !important" : "unset",
                    right: direction === "rtl" ? "1.75rem !important" : "unset",
                    fontSize: "small",
                    color: "#000000",
                    fontWeight: 400,
                    overflow: "unset",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "small",
                    color: "#000000",
                    fontWeight: 400,
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    fontWeight: 400,
                    lineHeight: "17.34px",
                    "& legend": {
                      textAlign: direction === "rtl" ? "right" : "left",
                    },
                  },
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleSubmit()}
                variant="contained"
                fullWidth
                style={{
                  margin: "0 16px 10px 16px",
                  padding: 10,
                  textTransform: "capitalize",
                  borderRadius: "0px",
                }}
              >
                {t("userInfo.update_password")}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdatePasswordDialog;
