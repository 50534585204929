import { FormControl, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import imagepath from "../../../constant/imagepath";
import {
  BackConatiner,
  BodyTypography,
  FormContainer,
  FormContainerWrapper,
  HeadingTypography,
  ImageContainer,
  LanguageBox,
  StyledImage,
} from "../styles/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CustomButton from "../../../components/Button";
import CustomLabel from "../../../components/Label";
import CustomTextField from "../../../components/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { userService } from "../../../api/services/userService";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ShowToast from "../../../utils/ShowToast";
import { CurrentLanguagePath } from "../../../utils/helper";
import { useLanguageStore } from "../../../zustand/store/language";

function ForgotPassword() {
  const { t, i18n } = useTranslation();
  const [customerId, setCustomerId] = useState<string | null>(null);

  const direction = i18n.dir();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (customerId) {
      const path = CurrentLanguagePath(`mobile-otp?id=${customerId}`);
      console.log("Navigating to:", path);
      navigate(path, {
        state: {
          from: "forgot",
        },
      });
      setCustomerId(null);
    }
  }, [customerId, navigate]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .required(t("errors.email.required"))
      .email(t("errors.email.invalid"))
      .matches(
        /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|hotmail\.com|icloud\.com|outlook\.com)$/,
        t("errors.email.invalid")
      ),
  });

  const handleSendOTP = async (values: any) => {
    const request = {
      email: values.email,
    };

    try {
      const response: any = await userService.forgotPassword(request);
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        setCustomerId(response.data.customer_id);
        // const path = CurrentLanguagePath(`mobile-otp?id=${response.data.customer_id}`);
        // console.log('Navigating to:', path);

        // navigate(path, {
        //   state: {
        //     from: "forgot",
        //   },
        // });
        // window.location.reload()
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
    }
  };
  const { setLanguage, language } = useLanguageStore();
  const { pathname, search: query } = useLocation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
    const segments = pathname.split("/").filter(Boolean);
    const supportedLanguages = ["en", "ar"];
    if (supportedLanguages.includes(language)) {
      segments[0] = language;
    } else {
      segments.unshift(language);
    }
    const newPath = `/${segments.join("/")}${query || ""}`;
    setLanguage(language);
    document.body.dir = language === "ar" ? "rtl" : "ltr";
    navigate(newPath, { replace: true });
  };

  return (
    <Grid container sx={{ direction: language === "ar" ? "rtl" : "ltr" }}>
      <ImageContainer item xs={12} sm={12} md={7}>
        <StyledImage src={imagepath.forgotPassword} alt="Forgot Password" />
      </ImageContainer>
      <FormContainerWrapper item xs={12} sm={12} md={5}>
        <LanguageBox dir={direction} sx={{ cursor: "pointer" }}>
          <Typography onClick={() => changeLanguage("en")}>English</Typography>{" "}
          <Typography sx={{ mx: 1 }}>|</Typography>
          <Typography onClick={() => changeLanguage("ar")}>عربي</Typography>
        </LanguageBox>
        <FormContainer>
          <BackConatiner onClick={handleBack}>
            <ArrowBackIosIcon
              fontSize="small"
              className="rtl-transform"
              sx={{ cursor: "pointer" }}
            />
            <Typography
              variant="body1"
              sx={{
                marginRight: direction === "rtl" ? "5px" : "unset",
                cursor: "pointer",
              }}
            >
              {t("forgot_password.back")}
            </Typography>
          </BackConatiner>
          <HeadingTypography variant="h4">{t("forgot_password.forgotText")}</HeadingTypography>
          <BodyTypography variant="body1" gutterBottom>
            {t("forgot_password.forgotDescription")}
          </BodyTypography>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSendOTP(values)}
          >
            {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <CustomLabel>{t("forgot_password.email")}</CustomLabel>
                  <CustomTextField
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder={t("forgot_password.emailPlaceHolder")}
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email ? (
                    <Typography color="error" variant="body2">
                      {errors.email}
                    </Typography>
                  ) : null}
                </FormControl>
                <CustomButton
                  onClick={() => handleSubmit()}
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 4,
                    mb: 4,
                    textTransform: "capitalize",
                    borderRadius: "0px",
                  }}
                >
                  {t("forgot_password.sendOtp")}
                </CustomButton>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </FormContainerWrapper>
    </Grid>
  );
}

export default ForgotPassword;
