import { useCallback, useEffect, useState } from "react";
import {
  Container,
  TextField,
  Grid,
  Box,
  Button,
  Switch,
  FormControlLabel,
  Typography,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import COLORS from "../../../constant/colors";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import LeftMenuBar from "../../../components/LeftMenuBar";
import { useTranslation } from "react-i18next";
import { shippingService } from "../../../api/services/shippingService";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  useLoadScript,
  Autocomplete,
  AutocompleteProps,
} from "@react-google-maps/api";
import { google_place_api } from "../../../utils/helper";
import * as Yup from "yup";
import { Formik } from "formik";
import { ShippingAddress } from "../../../utils/type";
import ShowToast from "../../../utils/ShowToast";
import axios from "axios";
import { Helmet } from "react-helmet";

const NewAddress = () => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const navigate = useNavigate();
  const { id } = useParams();
  const [city, setCity] = useState<{ city_name: string }[]>([]);
  const [address, setAddress] = useState("");
  const [searchResult, setSearchResult] =
    useState<google.maps.places.Autocomplete>();
  const [initialValues, setInitialValues] = useState({
    address: "",
    city: "",
    first_name: "",
    last_name: "",
    country_code: "+966",
    phone: "",
    is_default: false,
  });
  const [currentLocation, setCurrentLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const handleLocationChange = (latLng: { lat: number; lng: number }) => {
    setCurrentLocation(latLng);
  };

  const handleCityChange = (newCity: string) => {
    let isValid = city.find((cityName) => cityName.city_name === newCity);
    if (isValid) {
      setInitialValues((prev) => ({
        ...prev,
        city: newCity,
      }));
    } else {
      setInitialValues((prev) => ({
        ...prev,
        city: "",
      }));
    }
  };

  function onLoad(autocomplete?: any) {
    if (autocomplete) {
      setSearchResult(autocomplete);
    }
  }

  useEffect(() => {
    const getCity = async () => {
      try {
        const response = await shippingService.getCities();
        if (response && response.status === 200) {
          setCity(response.data);
        } else {
          console.log("error message:-", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getCity();
  }, []);

  useEffect(() => {
    const getShippingDetails = async () => {
      try {
        const request = {
          shipping_address_id: id,
        };
        const response = await shippingService.getShippingAddressDetails(
          request
        );
        if (response && response.status === 200) {
          setInitialValues((prev: any) => ({
            ...prev,
            address: response.data.address,
            city: response.data.city,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            country_code: response.data.country_code,
            phone: response.data.phone,
            is_default: response.data.is_default,
          }));
          setAddress(response.data.address);
          if (response.data.latitude && response.data.longitude) {
            setCurrentLocation({
              lat: Number(response.data.latitude),
              lng: Number(response.data.longitude),
            });
          }
        } else {
          console.log("error message:-", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (id) {
      getShippingDetails();
    }
  }, [id]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: google_place_api,
    libraries: ["places"],
  });

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCurrentLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });

            fetchAddress(position.coords.latitude, position.coords.longitude);
          },
          (error) => {
            console.log("Error getting current location:", error);
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    } else {
      alert("Geolocation is not supported in your browser.");
    }
  }, []);

  useEffect(() => {
    if (!id && isLoaded) {
      getLocation();
    }
  }, [getLocation, id, isLoaded]);

  const handleDragEnd = (e: any) => {
    if (e.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      fetchAddress(lat, lng);
      setCurrentLocation({ lat, lng });
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results && results[0]) {
          fetchAddress(lat, lng);
          setAddress(results[0].formatted_address);
        }
      });
    }
  };

  const fetchAddress = async (lat: number, lng: number) => {
    try {
      const response = await axios.get<any>(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${google_place_api}`
      );

      let newCity = "";
      for (let component of response.data.results[0].address_components) {
        for (let type of component.types) {
          if (type === "locality" || type === "administrative_area_level_1") {
            newCity = component.long_name;
            break;
          }
        }
        if (newCity) break;
      }
      console.log(
        response.data.results[0].formatted_address,
        "response.data.results[0]"
      );
      if (response.data.results.length > 0) {
        setInitialValues((pri) => ({
          ...pri,
          address: response.data.results[0].formatted_address,
        }));
        setAddress(response.data.results[0].formatted_address);
        const isValidCity = city.find(
          (cityName: any) => cityName.city_name === newCity
        );
        if (isValidCity) {
          setInitialValues((prev) => ({
            ...prev,
            city: newCity,
          }));
        } else {
          setInitialValues((prev) => ({
            ...prev,
            city: "",
          }));
        }
      } else {
        console.log("Address not found");
      }
    } catch (error) {
      console.log("Error fetching address:", error);
    }
  };

  const validationSchema = Yup.object({
    address: Yup.string().required(t("errors.address")),
    city: Yup.string().required(t("errors.city")),
    first_name: Yup.string()
      .required(t("errors.first_name.required"))
      .matches(/^[A-Za-z ]*$/, t("errors.first_name.invalid"))
      .max(50, t("errors.first_name.max")),
    last_name: Yup.string()
      .required(t("errors.last_name.required"))
      .matches(/^[A-Za-z ]*$/, t("errors.last_name.invalid"))
      .max(50, t("errors.last_name.max")),
    phone: Yup.string()
      .required(t("errors.mobile.required"))
      .matches(/^5\d{8}$/, t("errors.mobile.invalid")),
  });

  const addShippingAddress = async (values: ShippingAddress) => {
    try {
      const request = {
        is_default: values.is_default,
        city: values.city,
        first_name: values.first_name,
        last_name: values.last_name,
        country_code: values.country_code,
        phone: values.phone,
        address: values.address,
        latitude: currentLocation.lat,
        longitude: currentLocation.lng,
      };
      const response = await shippingService.addShippingAddress(request);
      if (response && response.status === 200) {
        ShowToast((response as any)?.message, "success");
        navigate(-1);
      } else {
        console.log("error message:-", response.data);
      }
    } catch (error: any) {
      ShowToast(error?.message, "error", "bottom-left");
      console.log(error);
    }
  };
  const updateShippingAddress = async (values: ShippingAddress) => {
    try {
      const request = {
        shipping_address_id: id,
        is_default: values.is_default,
        city: values.city,
        first_name: values.first_name,
        last_name: values.last_name,
        country_code: values.country_code,
        phone: values.phone,
        address: values.address,
        latitude: currentLocation.lat,
        longitude: currentLocation.lng,
      };

      const response = await shippingService.updateShippingAddress(request);
      if (response && response.status === 200) {
        ShowToast((response as any)?.message, "success");
        navigate(-1);
      } else {
        console.log("error message:-", response.data);
      }
    } catch (error: any) {
      ShowToast(error?.message, "error", "bottom-left");
      console.log(error);
    }
  };
  const locationSelected = () => {
    if (searchResult) {
      const place = searchResult.getPlace();
      if (place.geometry?.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setCurrentLocation({ lat, lng });
        fetchAddress(lat, lng);
      }
    }
  };

  return (
    <Box sx={{ background: "rgb(164 161 170 / 20%)", minHeight: "100vh" }}>
      {/* <Helmet>
        <title>Add New Address</title>
      </Helmet> */}

      <Container maxWidth={"xl"}>
        <Grid
          container
          pb={8}
          sx={{
            paddingLeft: { xs: "0%", sm: "0%" },
            paddingRight: { xs: "0%", sm: "0%" },
            marginLeft: { xs: "0%", sm: "0%" },
            marginRight: { xs: "0%", sm: "0%" },
            paddingTop: { xs: "0%", sm: "2%" },
          }}
          columns={12}
        >
          <LeftMenuBar selectedItem={2} />

          <Grid item xs={12} lg={8} md={8} gap={0}>
            <Box sx={{ position: "relative" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ChevronLeft
                  sx={{
                    [direction === "rtl" ? "marginRight" : "marginLeft"]:
                      "-10px",
                    padding: "0px !important",
                    fontSize: "20px",
                    width: "25px",
                  }}
                  className="rtl-transform"
                  onClick={() => navigate(-1)}
                />
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "26.01px",
                    textAlign: {
                      xs: direction === "rtl" ? "right" : "left",
                      sm: direction === "rtl" ? "right" : "left",
                    },
                    marginTop: { xs: "0px", sm: "0px" },
                  }}
                >
                  {t("address.my_addresses")}
                </Typography>
              </div>
              <Box my={2}>
                {isLoaded && (
                  <div
                    style={{ position: "relative", maxWidth: "432px" }}
                    className="map-container"
                  >
                    <GoogleMap
                      center={currentLocation}
                      mapContainerStyle={{
                        width: "100%",
                        maxWidth: "432px",
                        height: "240px",
                        border: "1px solid white",
                        borderRadius: "4px",
                      }}
                      zoom={12}
                      options={{
                        draggable: true,
                        scrollwheel: false,
                        keyboardShortcuts: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        zoomControl: true,
                        streetViewControl: false,
                        controlSize: 25,
                      }}
                    >
                      <MarkerF
                        position={currentLocation}
                        draggable={true}
                        onDragEnd={handleDragEnd}
                      />
                    </GoogleMap>
                    <button
                      onClick={getLocation}
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        left: "10px",
                        backgroundColor: "#fff",
                        border: "2px solid #fff",
                        borderRadius: "50%",
                        boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                        cursor: "pointer",
                        padding: "4px",
                        zIndex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        style={{ width: "20px", height: "20px" }}
                      >
                        <path d="M24 16a8 8 0 1 0 0 16 8 8 0 1 0 0-16zm17.88 6A17.99 17.99 0 0 0 26 6.12V2h-4v4.12A17.99 17.99 0 0 0 6.12 22H2v4h4.12A17.99 17.99 0 0 0 22 41.88V46h4v-4.12A17.99 17.99 0 0 0 41.88 26H46v-4h-4.12zM24 38c-7.73 0-14-6.27-14-14s6.27-14 14-14 14 6.27 14 14-6.27 14-14 14z"></path>
                      </svg>
                    </button>
                  </div>
                )}
              </Box>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: ShippingAddress) => {
                  if (!id) {
                    addShippingAddress(values);
                  } else {
                    updateShippingAddress(values);
                  }
                }}
                validateOnChange
                enableReinitialize
                validateOnMount
              >
                {({
                  errors,
                  touched,
                  values,
                  setFieldValue,
                  handleChange,
                  handleSubmit,
                }) => {
                  return (
                    <div>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            lineHeight: "26.01px",
                            marginBottom: "8px",
                          }}
                        >
                          {t("address.address_details")}
                        </Typography>
                        <Grid
                          container
                          spacing={{ xs: 0, sm: 2 }}
                          sx={{ width: "100% !important" }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            mb={{ xs: "10px", sm: "unset" }}
                          >
                            <Autocomplete
                              onLoad={onLoad}
                              onPlaceChanged={locationSelected}
                              options={{
                                bounds: new google.maps.LatLngBounds(
                                  new google.maps.LatLng(16.0, 34.5),
                                  new google.maps.LatLng(32.0, 55.0)
                                ),
                                componentRestrictions: { country: "sa" },
                              }}
                            >
                              <TextField
                                fullWidth
                                label={t("address.address")}
                                variant="outlined"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                sx={{
                                  borderRadius: "4px",
                                  "& label": {
                                    transformOrigin:
                                      direction === "rtl"
                                        ? "right !important"
                                        : "unset",
                                    left:
                                      direction === "rtl"
                                        ? "inherit !important"
                                        : "unset",
                                    right:
                                      direction === "rtl"
                                        ? "1.75rem !important"
                                        : "unset",
                                    fontSize: "small",
                                    color: "#000000",
                                    fontWeight: 400,
                                    overflow: "unset",
                                  },
                                  "& .MuiInputLabel-root": {
                                    fontSize: "small",
                                    color: "#000000",
                                    fontWeight: 400,
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    height: "50px",
                                    backgroundColor: COLORS.WHITE,
                                    fontWeight: 400,
                                    lineHeight: "17.34px",
                                    "& legend": {
                                      textAlign:
                                        direction === "rtl" ? "right" : "left",
                                    },
                                  },
                                  ".css-1c4urck-MuiFormLabel-root-MuiInputLabel-root":
                                    {
                                      transform:
                                        "translate(14px, 16px) scale(1)",
                                    },
                                }}
                              />
                            </Autocomplete>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              select
                              fullWidth
                              label={t("address.city")}
                              variant="outlined"
                              name="city"
                              value={values.city}
                              onChange={handleChange}
                              error={touched.city && !!errors.city}
                              helperText={touched.city && errors.city}
                              sx={{
                                borderRadius: "4px",
                                "& label": {
                                  transformOrigin:
                                    direction === "rtl"
                                      ? "right !important"
                                      : "unset",
                                  left:
                                    direction === "rtl"
                                      ? "inherit !important"
                                      : "unset",
                                  right:
                                    direction === "rtl"
                                      ? "1.75rem !important"
                                      : "unset",
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                  overflow: "unset",
                                },
                                "& .MuiInputLabel-root": {
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                },
                                "& .MuiOutlinedInput-root": {
                                  height: "50px",
                                  backgroundColor: COLORS.WHITE,
                                  fontWeight: 400,
                                  lineHeight: "17.34px",
                                  "& legend": {
                                    textAlign:
                                      direction === "rtl" ? "right" : "left",
                                  },
                                },
                                "& .MuiSelect-icon": {
                                  left:
                                    direction === "rtl"
                                      ? "7px !important"
                                      : "unset",
                                  right: direction === "rtl" ? "unset" : "7px",
                                },
                              }}
                            >
                              {city.map((city, index) => (
                                <MenuItem key={index} value={city.city_name}>
                                  {city.city_name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box mt={1}>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            lineHeight: "26.01px",
                            marginBottom: "8px",
                          }}
                        >
                          {t("address.contact_details")}
                        </Typography>
                        <Grid
                          container
                          spacing={{ xs: 0, sm: 2 }}
                          sx={{ width: "100% !important" }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            mb={{ xs: "10px", sm: "unset" }}
                          >
                            <TextField
                              fullWidth
                              label={t("address.first_name")}
                              variant="outlined"
                              name="first_name"
                              value={values.first_name}
                              onChange={handleChange}
                              error={touched.first_name && !!errors.first_name}
                              helperText={
                                touched.first_name && errors.first_name
                              }
                              inputProps={{
                                maxLength: 50,
                              }}
                              sx={{
                                borderRadius: "4px",
                                "& label": {
                                  transformOrigin:
                                    direction === "rtl"
                                      ? "right !important"
                                      : "unset",
                                  left:
                                    direction === "rtl"
                                      ? "inherit !important"
                                      : "unset",
                                  right:
                                    direction === "rtl"
                                      ? "1.75rem !important"
                                      : "unset",
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                  overflow: "unset",
                                },
                                "& .MuiInputLabel-root": {
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                },
                                "& .MuiOutlinedInput-root": {
                                  height: "50px",
                                  backgroundColor: COLORS.WHITE,
                                  fontWeight: 400,
                                  lineHeight: "17.34px",
                                  "& legend": {
                                    textAlign:
                                      direction === "rtl" ? "right" : "left",
                                  },
                                },
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            mb={{ xs: "10px", sm: "unset" }}
                          >
                            <TextField
                              fullWidth
                              label={t("address.last_name")}
                              variant="outlined"
                              name="last_name"
                              value={values.last_name}
                              onChange={handleChange}
                              error={touched.last_name && !!errors.last_name}
                              helperText={touched.last_name && errors.last_name}
                              inputProps={{
                                maxLength: 50,
                              }}
                              sx={{
                                borderRadius: "4px",
                                "& label": {
                                  transformOrigin:
                                    direction === "rtl"
                                      ? "right !important"
                                      : "unset",
                                  left:
                                    direction === "rtl"
                                      ? "inherit !important"
                                      : "unset",
                                  right:
                                    direction === "rtl"
                                      ? "1.75rem !important"
                                      : "unset",
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                  overflow: "unset",
                                },
                                "& .MuiInputLabel-root": {
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                },
                                "& .MuiOutlinedInput-root": {
                                  height: "50px",
                                  backgroundColor: COLORS.WHITE,
                                  fontWeight: 400,
                                  lineHeight: "17.34px",
                                  "& legend": {
                                    textAlign:
                                      direction === "rtl" ? "right" : "left",
                                  },
                                },
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ display: "flex", width: "100%" }}
                          >
                            <TextField
                              label={t("address.country_code")}
                              variant="outlined"
                              defaultValue={values.country_code || "+966"}
                              name="country_code"
                              disabled
                              // value={values.country_code}
                              sx={{
                                borderRadius: "4px",
                                marginRight:
                                  direction === "rtl" ? "unset" : "5px",
                                "& label": {
                                  transformOrigin:
                                    direction === "rtl"
                                      ? "right !important"
                                      : "unset",
                                  left:
                                    direction === "rtl"
                                      ? "inherit !important"
                                      : "unset",
                                  right:
                                    direction === "rtl"
                                      ? "1.75rem !important"
                                      : "unset",
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                  overflow: "unset",
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                                ".css-14olnj9-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled":
                                  {
                                    color: "#000000 !important",
                                  },
                                "& .MuiInputLabel-root": {
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                },
                                "& .MuiOutlinedInput-root": {
                                  height: "50px",
                                  backgroundColor: COLORS.WHITE,
                                  fontWeight: 400,
                                  lineHeight: "17.34px",
                                  "& legend": {
                                    textAlign:
                                      direction === "rtl" ? "right" : "left",
                                  },
                                },
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Box
                                      component="img"
                                      src="https://vogacloset.com/3.0.28/static/media/sa.svg"
                                      alt="Dubai Flag"
                                      sx={{ width: "20px", height: "auto" }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              fullWidth
                              label={t("address.phone_number")}
                              placeholder={"5XXXXXXXXX"}
                              name="phone"
                              value={values.phone}
                              onChange={(event: any) => {
                                setFieldValue(
                                  "phone",
                                  event.target.value.replace(/[^0-9]+/g, "")
                                );
                              }}
                              error={touched.phone && !!errors.phone}
                              helperText={touched.phone && errors.phone}
                              variant="outlined"
                              inputProps={{
                                maxLength: 9,
                                inputMode: "numeric",
                              }}
                              sx={{
                                borderRadius: "4px",
                                marginRight:
                                  direction === "rtl" ? "5px" : "unset",
                                "& label": {
                                  transformOrigin:
                                    direction === "rtl"
                                      ? "right !important"
                                      : "unset",
                                  left:
                                    direction === "rtl"
                                      ? "inherit !important"
                                      : "unset",
                                  right:
                                    direction === "rtl"
                                      ? "1.75rem !important"
                                      : "unset",
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                  overflow: "unset",
                                },
                                "& .MuiInputLabel-root": {
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                },
                                "& .MuiOutlinedInput-root": {
                                  height: "50px",
                                  backgroundColor: COLORS.WHITE,
                                  fontWeight: 400,
                                  lineHeight: "17.34px",
                                  "& legend": {
                                    textAlign:
                                      direction === "rtl" ? "right" : "left",
                                  },
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box mt={1}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100% !important",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "23.12px",
                                }}
                              >
                                {t("address.default_delivery_address")}
                              </Typography>
                              <FormControlLabel
                                className="rtl-transform"
                                control={
                                  <Switch
                                    value={values.is_default}
                                    checked={values.is_default === true}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "is_default",
                                        e.target.checked
                                      )
                                    }
                                  />
                                }
                                label=""
                                labelPlacement={
                                  direction === "rtl" ? "end" : "start"
                                }
                                sx={{ margin: "0px !important" }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Box>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        mt={1}
                        mb={1}
                        sx={{ xs: 0, sm: "16px" }}
                      >
                        <Button
                          onClick={() => {
                            handleSubmit();
                          }}
                          fullWidth
                          variant="contained"
                          sx={{
                            borderRadius: "0px",
                            width: { xs: "100%", sm: "96%" },
                            height: "44px",
                          }}
                        >
                          {t("address.save")}
                        </Button>
                      </Grid>
                    </div>
                  );
                }}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NewAddress;
