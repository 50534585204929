import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Paper,
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OrderItems, RefundReasons } from "../types/checkout";
import { useTranslation } from "react-i18next";
import { CurrentLanguagePath } from "../utils/helper";

interface ProductDialogProps {
  open: boolean;
  onClose: () => void;
  products: OrderItems[];
  returnReasons: RefundReasons[];
  onSubmitSelected: (selectedItems: {
    order_items_ids: number[];
    reason_of_refund: string;
    description: string;
  }) => void;
}

const ProductDialog: React.FC<ProductDialogProps> = ({
  open,
  onClose,
  products,
  returnReasons,
  onSubmitSelected,
}) => {
  const { i18n, t } = useTranslation();
  const direction = i18n.dir();
  const rtl = direction === "rtl" ? "left" : "right";
  const [selectedOrderItems, setSelectedOrderItems] = useState<number[]>([]);
  const [selectedReason, setSelectedReason] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  // Check if at least one item is selected
  const hasSelectedItems = selectedOrderItems.length > 0;

  const handleCheckboxChange = (orderItemId: number) => {
    setSelectedOrderItems((prevSelected) => {
      if (prevSelected.includes(orderItemId)) {
        // If already selected, remove it
        return prevSelected.filter((id) => id !== orderItemId);
      } else {
        // Otherwise, add it
        return [...prevSelected, orderItemId];
      }
    });
  };

  const handleReasonChange = (event: SelectChangeEvent<string>) => {
    setSelectedReason(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedOrderItems.length > 0 && selectedReason && description) {
      onSubmitSelected({
        order_items_ids: selectedOrderItems,
        reason_of_refund: selectedReason,
        description: description,
      });
      resetDialogFields();
    }
    onClose();
  };
  const resetDialogFields = () => {
    setSelectedOrderItems([]);
    setSelectedReason("");
    setDescription("");
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6">{t("order.order_details")}</Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute", [rtl]: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          "@media (max-width: 320px)": {
            padding: " 20px 13px",
          },
        }}
      >
        {products.map((vendorOrder) => (
          <Box key={vendorOrder.vendor_order_id} sx={{ mb: 2 }}>
            <Typography variant="h6">{vendorOrder.vendor_name}</Typography>
            {vendorOrder.items.map((item, index) => (
              <Paper key={item.order_item_id} elevation={0} sx={{ mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: { xs: "10px", sm: "20px" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <img
                      src={item.product_image_url}
                      alt={item.product_name}
                      style={{
                        maxWidth: "100px",
                        minHeight: "100px",
                        borderRadius: "4px",
                        objectFit: "cover",
                      }}
                    />
                    <FormControlLabel
                      sx={{
                        position: "absolute",
                        top: "50px",
                        left: direction === "rtl" ? "unset" : "-20px",
                        right: direction === "rtl" ? "-46px" : "unset",
                      }}
                      control={
                        <Checkbox
                          disableRipple
                          disabled={!item.is_returnable}
                          checked={
                            !item.is_returnable ||
                            selectedOrderItems.includes(
                              Number(item.order_item_id)
                            )
                          }
                          onChange={() =>
                            handleCheckboxChange(Number(item.order_item_id))
                          }
                          color="primary"
                          sx={{
                            ".css-i4bv87-MuiSvgIcon-root": {
                              width: "18px",
                              height: "18px",
                              padding: "0px",
                            },
                          }}
                        />
                      }
                      label=""
                    />
                  </Box>
                  {/* Product Information  */}
                  <Box
                    sx={{
                      flex: 1,
                      ml: direction === "rtl" ? 0 : 2,
                      mr: direction === "rtl" ? 2 : 0,
                      textAlign: direction === "rtl" ? "right" : "left",
                    }}
                  >
                    <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
                      {item.product_name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t("order.price")}: {item.sale_price}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t("order.color")}: {item.color_name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t("order.size")}: {item.size}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t("order.quantity")}: {item.quantity}
                    </Typography>
                    {!item.is_returnable &&
                      vendorOrder.status === "cancelled" ? (
                      <Typography variant="body2" color="red">
                        {t("order.item_cancelled")}
                      </Typography>
                    ) : (
                      !item.is_returnable && (
                        <Typography variant="body2" color="red">
                          {t("order.item_returned")}
                        </Typography>
                      )
                    )}
                  </Box>
                </Box>
              </Paper>
            ))}
          </Box>
        ))}
        {/* Return Reason dropdown, displayed when at least one item is selected  */}
        {hasSelectedItems && (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel sx={{
              left: direction === "rtl" ? "unset" : "7px",
              right: direction === "rtl" ? "18px" : "unset",
              fontSize: "small",
            }}>{t("order.return_reason")}</InputLabel>
            <Select
              value={selectedReason}
              onChange={handleReasonChange}
              label={t("order.return_reason")}
              sx={{
                "& .MuiSelect-icon": {
                  left: direction === "rtl" ? "7px" : "unset",
                  right: direction === "rtl" ? "unset" : "7px",
                },
                "& .MuiOutlinedInput-input": {
                  paddingRight: "12px !important",
                },
                "& legend": {
                  textAlign: direction === "rtl" ? "right" : "left",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "small",
                  color: "#000000",
                  fontWeight: 400,
                },

              }}
            >
              {returnReasons.map((reason) => (
                <MenuItem key={reason.id} value={reason.title}>
                  {reason.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {hasSelectedItems && (
          <TextField
            fullWidth
            label={t("order.description")}
            value={description}
            onChange={handleDescriptionChange}
            sx={{
              mt: 2,
              "& label": {
                transformOrigin:
                  direction === "rtl" ? "right !important" : "unset",
                left: direction === "rtl" ? "inherit !important" : "unset",
                right: direction === "rtl" ? "1.75rem !important" : "unset",
              },
              "& .MuiOutlinedInput-root": {
                direction: direction === "rtl" ? "rtl" : "ltr",
                textAlign: direction === "rtl" ? "right" : "left",
                "& legend": {
                  textAlign: direction === "rtl" ? "right" : "left",
                },
              },
              "& .MuiInputLabel-root": {
                fontSize: "small",
                color: "#000000",
                fontWeight: 400,
              },

            }}
            multiline
            rows={3}
          />
        )}
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "300",
            lineHeight: "16.94px",
            textAlign: "center",
          }}
        >
          {t("order.check_out_our")}&nbsp;
          <span style={{ fontSize: "14px", fontWeight: "500" }}>
            <a
              href={CurrentLanguagePath("return-policy")}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#000", textDecoration: "underline" }}
            >
              {t("order.return_policy")}
            </a>
          </span>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleSubmit}
          disabled={!hasSelectedItems || !selectedReason || !description}
        >
          {t("order.place_return")}
        </Button>
      </Box>
    </Dialog>
  );
};

export default ProductDialog;
