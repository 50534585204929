import { create } from "zustand";

type LanguageState = {
  language: string;
  setLanguage: (lang: string) => void;
};

export const useLanguageStore = create<LanguageState>((set) => ({
  language: localStorage.getItem("i18nextLng") || "ar",
  setLanguage: (lang) => set({ language: lang }),
}));
