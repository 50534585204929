import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  useMediaQuery,
  useTheme,
  Container,
} from "@mui/material";
import LeftMenuBar from "../../components/LeftMenuBar";
import { useTranslation } from "react-i18next";
import { productService } from "../../api/services/productService";
import { Products } from "../../utils/type";
import RecentProductSlider from "../../components/ProductCarousel";
import { cartService } from "../../api/services/cartService";
import ShowToast from "../../utils/ShowToast";
import { useCartStore } from "../../zustand/store/cartData";
import { wishListService } from "../../api/services/wishListService";
import { Helmet } from "react-helmet";
import { useLanguageStore } from "../../zustand/store/language";
import { AddToCart, AddToWishlist } from "../../tagManager/gtag";

const Account = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [products, setProducts] = useState<Products[]>([]);
  const { getCartItems } = useCartStore();
  const [isLoading, setIsLoading] = useState(false);
  const { language } = useLanguageStore();

  const fetchRecentlyViewedProducts = async () => {
    const request = {
      page: 1,
    };
    try {
      const response = await productService.getRecentlyViewedProducts(request);
      setProducts(response.data.products);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchRecentlyViewedProducts();
  }, [language]);
  const addToCart = async (
    productId: string,
    variantId: string,
    quantity: string,
    product: Products
  ) => {
    const params = {
      product_id: productId,
      product_variant_id: variantId,
      quantity: quantity,
    };
    try {
      const response: any = await cartService.addToCart(params);
      if (response && response.status === 200) {
        ShowToast(response.data.message, "success");
        getCartItems();
        AddToCart({
          currency: "SAR",
          value: product.price,
          items: {
            productId: product.product_id,
            productName: product.product_title,
            price: product.price,
            variantId: product.product_variant_id,
            vendor: product.vendor_name,
          },
        });
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log("Error adding to cart:", error);
    }
  };

  const addWishlist = async (
    productId: string,
    variantId: string,
    product: Products
  ) => {
    try {
      const params = {
        product_id: productId,
        product_variant_id: variantId,
      };
      setIsLoading(true);
      const response: any = await wishListService.addToWishList(params, {
        showLoader: false,
      });
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        fetchRecentlyViewedProducts();
        AddToWishlist({
          currency: "SAR",
          value: product.price,
          items: {
            productId: product.product_id,
            productName: product.product_title,
            price: product.price,
            variantId: product.product_variant_id,
            vendor: product.vendor_name,
          },
        });
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      setIsLoading(false);
      ShowToast(error.message, "error", "bottom-left");
      console.log("Error adding to wishlist:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const removeWishList = async (wishListId: string) => {
    const request = {
      wishlist_id: wishListId,
    };
    setIsLoading(true);
    try {
      const response: any = await wishListService.removeWishList(request);

      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        fetchRecentlyViewedProducts();
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      setIsLoading(false);
      ShowToast(error.message, "error", "bottom-left");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        background: "rgb(164 161 170 / 20%)",
        minHeight: "62vh",
      }}
    >
      <Helmet>
        <title>Account</title>
      </Helmet>

      <Container maxWidth={"xl"}>
        <Grid
          container
          pb={8}
          sx={{
            paddingLeft: { xs: "0%", sm: "0%" },
            paddingRight: { xs: "0%", sm: "0%" },
            marginLeft: { xs: "0%", sm: "0%" },
            marginRight: { xs: "0%", sm: "0%" },
            // marginTop: { xs: "0%", sm: "2%" },
            paddingTop: { xs: "0%", sm: "2%" },
          }}
          columns={12}
        >
          <LeftMenuBar selectedItem={0} isMenuShow={true} />

          {!isMobile && (
            <Grid
              item
              xs={12}
              lg={8}
              md={8}
              gap={0}
              style={{ position: "relative" }}
            >
              <Typography
                fontWeight={500}
                fontSize={"32px"}
                lineHeight={"46.24px"}
                textAlign={"center"}
              >
                {t("account.recently_view")}
              </Typography>
              <RecentProductSlider
                products={products}
                onAddToCart={addToCart}
                translation={t}
                handleWishlist={(index) => {
                  if (!products) {
                    return;
                  }
                  const {
                    product_id,
                    product_variant_id,
                    wishlist_id,
                    isAddedWishlist,
                  } = products[index];
                  if (!isLoading) {
                    if (isAddedWishlist) {
                      removeWishList(wishlist_id);
                    } else {
                      addWishlist(
                        product_id.toString(),
                        product_variant_id.toString(),
                        products[index]
                      );
                    }
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default Account;
