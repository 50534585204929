import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Checkbox,
  Divider,
  MenuItem,
  AppBar,
  Toolbar,
  Badge,
  InputAdornment,
  IconButton,
  AutocompleteChangeDetails,
  FormHelperText,
} from "@mui/material";
import COLORS from "../../constant/colors";
import styled from "@emotion/styled";
import imagepath from "../../constant/imagepath";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { cartService } from "../../api/services/cartService";
import ShowToast from "../../utils/ShowToast";
import { Close } from "@mui/icons-material";
import { shippingService } from "../../api/services/shippingService";
import { Field, FieldProps, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { CurrentLanguagePath, google_place_api } from "../../utils/helper";
import axios from "axios";
import useAuthStore from "../../zustand/store/user";
import { supportedLanguages } from "../../constant/baseUrl";
import { useLanguageStore } from "../../zustand/store/language";
import LoginDialog from "../../components/LogInDialog";
import { userService } from "../../api/services/userService";
import { checkoutServices } from "../../api/services/checkout";
import useCheckoutStore from "../../zustand/store/useCheckoutStore";
import { textFieldStyles } from "./styles";
import { AddCheckout, AddShippingInfo } from "../../tagManager/gtag";
import FormikErrorFocus from "formik-error-focus";

const StyledBadge = ({
  badgeContent,
  direction,
  isLogin,
}: {
  badgeContent: number;
  direction: string;
  isLogin: boolean;
}) => {
  return (
    <Badge
      badgeContent={isLogin ? badgeContent - 1 : badgeContent}
      color="secondary"
      overlap="circular"
      anchorOrigin={{
        vertical: "top",
        horizontal: direction === "rtl" ? "right" : "left",
      }}
      sx={{
        zIndex: 1,
        "& .MuiBadge-badge": {
          left: "9px",
          top: "-5px",
          padding: "0px",
          color: "#000",
          background: "#ffffff !important",
          minWidth: "12px",
          width: "42px",
          height: "42px",
          border: "2px solid black",
          fontFamily: "Inter",
          fontSize: "24px",
          fontWeight: "400",
          lineHeight: "29.05px",
          textAlign: "center",
          borderRadius: "50%",
          "@media (max-width: 500px)": {
            marginRight: "5px",
          },
        },
      }}
    />
  );
};

const CouponInputBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

interface LocationState {
  coupon_code?: string;
  message?: string;
}
interface AddressDetails {
  id: number;
  first_name: string;
  last_name: string;
  city: string;
  address: string;
  latitude: string;
  longitude: string;
  country_code: string;
  phone: string;
  is_default: boolean;
}
const Checkout = () => {
  const navigate = useNavigate();
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [coupon, setCoupon] = React.useState("");
  const [couponState, setCouponState] = React.useState("");
  const [address, setAddress] = useState("");
  const { t, i18n } = useTranslation();
  const { language } = useLanguageStore();
  const direction = i18n.dir();
  const borderBadge =
    direction === "ltr" ? "borderBottomLeftRadius" : "borderBottomRighrtRadius";
  const marginProps = direction === "ltr" ? "ml" : "mr";
  const { isLoggedIn, userInfo } = useAuthStore();
  const [addresses, setAddresses] = useState<AddressDetails[]>([]);
  const [showMap, setShowMap] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
  const [city, setCity] = useState<{ city_name: string }[]>([]);
  const [searchResult, setSearchResult] =
    useState<google.maps.places.Autocomplete>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const location = useLocation();
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    const state = location.state as LocationState;
    if (state && state.coupon_code) {
      setCouponState(state.coupon_code);
      setCoupon(state.coupon_code);
    }
    if (state && state.message) {
      setAppliedCoupon(state.message);
    }
  }, [location.state]);

  const { removeCoupon, setCheckoutData, checkoutData } = useCheckoutStore();

  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    address: "",
    city: "",
    selectedAddress: "",
    shipping_id: "",
    email: isLoggedIn ? userInfo?.email : "",
    is_want_to_register: !isLoggedIn,
    isLoggedIn: isLoggedIn,
    payment_method_id: "",
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: google_place_api,
    libraries: ["places"],
  });
  const { setLanguage } = useLanguageStore();
  const { pathname, search: query } = useLocation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
    const segments = pathname.split("/").filter(Boolean);
    if (supportedLanguages.includes(language)) {
      segments[0] = language;
    } else {
      segments.unshift(language);
    }
    const newPath = `/${segments.join("/")}${query || ""}`;
    setLanguage(language);
    navigate(newPath, { replace: true });
  };

  const handleClearCoupon = async () => {
    setCoupon("");
    setIsCouponApplied(false);
    setAppliedCoupon("");
    removeCoupon();
    await fetchCheckoutData();
  };

  const getAddress = async () => {
    try {
      const response = await shippingService.getShippingAddress();
      if (response && response.status === 200) {
        setAddresses(response.data);
      } else {
        console.log("error message:-", response.data);
      }
    } catch (error: any) {
      console.log(error?.message);
    }
  };

  useEffect(() => {
    const value = localStorage.getItem("checkoutFormValues");
    const savedFormValues = value ? JSON.parse(value) : null;
    if (savedFormValues) {
      setFormValues((prev: any) => ({
        ...prev,
        first_name: savedFormValues.first_name,
        last_name: savedFormValues.last_name,
        mobile_number: savedFormValues.mobile_number,
        address: savedFormValues.address,
        city: savedFormValues.city?.trim(),
        email: savedFormValues.email,
        payment_method_id: savedFormValues.payment_method_id,
        isLoggedIn: isLoggedIn,
        is_want_to_register: savedFormValues.is_want_to_register,
        selectedAddress: savedFormValues.selectedAddress,
        shipping_id: savedFormValues?.shipping_id ?? "",
      }));
      if (savedFormValues && savedFormValues.lat && savedFormValues.lng) {
        setMarkerPosition({ lat: savedFormValues.lat, lng: savedFormValues.lng })
        setSelectedPlace({ lat: savedFormValues.lat, lng: savedFormValues.lng })
      }
      setAddress(savedFormValues.address)
      if (savedFormValues.selectedAddress?.toLowerCase() === "new") {
        setShowMap(true)
        setFieldValue("shipping_id", "");
      }
      console.log(savedFormValues.city?.toLowerCase(), 'savedFormValues.city?.toLowerCase()')
    }
  }, []);

  function onLoad(autocomplete?: any) {
    if (autocomplete) {
      setSearchResult(autocomplete);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchCheckoutData(coupon);
      } catch (error) {
        console.error(
          "Failed to fetch checkout data on component mount:",
          error
        );
      }
    };
    fetchData();
  }, [couponState, language]);

  useEffect(() => {
    const removeExistingPaymentElements = () => {
      const existingScript = document.querySelector("script[src*='oppwa.com']");
      if (existingScript && document.body.contains(existingScript)) {
        document.body.removeChild(existingScript);
      }
      const existingForm = document.querySelector("form.paymentWidgets");
      if (existingForm && document.body.contains(existingForm)) {
        document.body.removeChild(existingForm);
      }
      const existingBackdrop = document.querySelector(".paymentBackdrop");
      if (existingBackdrop && document.body.contains(existingBackdrop)) {
        document.body.removeChild(existingBackdrop);
      }
    };
    removeExistingPaymentElements();
  }, []);

  const confirmOrder = async (values: any) => {
    try {
      await updateCheckoutData(values);
    } catch (error) {
      console.error("Failed to update checkout data:", error);
    }
  };

  const fetchCheckoutData = async (couponCode?: string) => {
    try {
      const params: any = {};
      if (couponCode) {
        params.coupon_code = couponCode;
      }
      const data = await checkoutServices.checkout(params);
      if (data && data.status === 200) {
        if (data && data?.data?.cart_items?.length === 0) {
          window.location.href = CurrentLanguagePath("cart")
        };
        setCheckoutData(data.data);
        if (couponCode) {
          setIsCouponApplied(true);
        }
      }
    } catch (error: any) {
      if (error && error.status === 400) {
        window.location.href = CurrentLanguagePath("cart");
        return;
      }
      console.error("Error fetching checkout data:", error);
    }
  };
  const updateCheckoutData = async (values: any) => {
    try {
      const params = {
        ...(coupon && { coupon_code: coupon }),
        ...values,
        type: "checkout_update",
        country_code: "+966",
      };
      const res: any = await checkoutServices.checkout(params);
      if (res && res.status === 200) {
        if (params) {
          localStorage.setItem("checkoutFormValues", JSON.stringify({ ...params, ...markerPosition }));
        }
        if (res.data.checkout_id) {
          await loadPaymentScript(
            res.data.payment_response.id,
            res.data.payment_method
          );
        }
        AddShippingInfo({
          shippingMethod: "Standard Delivery",
          shippingAddress: values.address,
        });
        AddCheckout({
          currency: "SAR",
          value: checkoutData.subtotal,
          items: checkoutData.cart_items.map((item) => ({
            productId: item.product_id,
            productName: item.product_name,
            price: item.price,
            variantId: item.product_variant_id,
            vendor: item.vendor_name,
            quantity: item.quantity,
          })),
          paymentMethod: res.data.payment_method,
        });
      } else {
        console.log("error message code");
      }
    } catch (error: any) {
      if (error && error.status === 400 && error.error.error_type === 1) {
        // ShowToast(error.message, 'error')
        handleClickOpen();
      } else {
        ShowToast(error.message, "error");
      }
      console.error("Error updating checkout data:", error);
      throw error;
    }
  };



  const loadPaymentScript = (checkoutId: string, method: string) => {
    return new Promise<void>((resolve, reject) => {
      const removeExistingPaymentElements = () => {
        const existingScript = document.querySelector(
          "script[src*='oppwa.com']"
        );
        if (existingScript && document.body.contains(existingScript)) {
          document.body.removeChild(existingScript);
        }
        const existingForm = document.querySelector("form.paymentWidgets");
        if (existingForm && document.body.contains(existingForm)) {
          document.body.removeChild(existingForm);
        }
        const existingBackdrop = document.querySelector(".paymentBackdrop");
        if (existingBackdrop && document.body.contains(existingBackdrop)) {
          document.body.removeChild(existingBackdrop);
        }
      };

      removeExistingPaymentElements();

      const script = document.createElement("script");
      script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      script.async = true;
      document.body.appendChild(script);

      var scriptValidation = document.createElement("script");
      scriptValidation.textContent = `
      var wpwlOptions = {
        style: "card",
        locale: '${language || "en"}',
        onReady: function (e) {
          document.querySelector('.wpwl-form-card').querySelector('.wpwl-button-pay').addEventListener('click', function (e) {
            validateHolder(e);
          });
        },
        onBeforeSubmitCard: function (e) {
          return validateHolder(e);
        }
      };

      function validateHolder(e) {
        var holder = document.querySelector('.wpwl-control-cardHolder').value;
        if (holder.trim().length < 3) {
          var errorElement = document.querySelector('.wpwl-hint-cardHolderError');
          if (!errorElement) {
            var errorDiv = document.createElement('div');
            errorDiv.className = 'wpwl-hint wpwl-hint-cardHolderError';
            errorDiv.textContent = 'Invalid card holder';
            document.querySelector('.wpwl-control-cardHolder').classList.add('wpwl-has-error');
            document.querySelector('.wpwl-control-cardHolder').after(errorDiv);
          }
          return false;
        }
        return true;
      };
    `;

      document.body.appendChild(scriptValidation);

      const backdrop = document.createElement("div");
      backdrop.className = "paymentBackdrop";

      const container = document.createElement("div");

      const closeButton = document.createElement("button");

      closeButton.onclick = () => {
        if (document.body.contains(backdrop)) {
          document.body.removeChild(backdrop);
        }
      };

      const form = document.createElement("form");
      form.className = "paymentWidgets";
      form.action = CurrentLanguagePath(`order-confirmation`);
      form.setAttribute("data-brands", method.toUpperCase());
      container.appendChild(form);
      container.appendChild(closeButton);
      backdrop.appendChild(container);
      const style = document.createElement("style");
      script.onload = () => {
        document.body.appendChild(backdrop);
        container.className = "paymentWidgetsContainer";
        closeButton.className = "closeButton";
        closeButton.innerHTML = "&times;";
        closeButton.title = "Close";
        style.textContent = `
        .paymentBackdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000; 
        }
        .paymentWidgetsContainer {
          position: relative;
          background: #fff;
          padding: 30px 30px 10px 30px;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        }
        .closeButton {
          position: absolute;
          top: -5px;
          right: 0px;
          background: none;
          border: none;
          font-size: 32px;
          cursor: pointer;
          color: silver;
          z-index: 1001;
          transition: color 0.3s;
        }
        .closeButton:hover {
          color: silver;
        }
        body {
          margin: 0;
          font-family: Arial, sans-serif;
          overflow: hidden;
        }
      `;
        document.head.appendChild(style);
        resolve();
      };

      script.onerror = () => {
        if (document.body.contains(backdrop)) {
          document.body.removeChild(backdrop);
        }
        // reject(console.log("Failed to load payment script"));
      };
    });
  };


  const applyCoupon = async () => {
    if (!coupon) {
      ShowToast(t("checkout.empty"), "error");
      return
    }
    if (isCouponApplied) {
      ShowToast(t("checkout.alreadyApplied"), "error");
      return
    }
    const request = {
      coupon_code: coupon,
    };
    try {
      const response: any = await cartService.applyCoupon(request);
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        setAppliedCoupon(response.data.discount_message);
        setIsCouponApplied(true);
        fetchCheckoutData(coupon);
      } else {
        ShowToast(response.message, "error");
        console.log("Error message:", response);
      }
    } catch (error: any) {
      if (error && error.status === 400) {
        ShowToast(error.message, "error");
        setCoupon("");
      }
      console.log("Catch Error:", error.message);
    }
  };

  const handleCouponChange = (event: any) => {
    setCoupon(event.target.value);
  };

  const validateEmail = (email: string): boolean => {
    const re =
      /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|hotmail\.com|icloud\.com|outlook\.com)$/;
    return re.test(email);
  };


  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("checkout.invalid_email"))
      .when("isLoggedIn", ([isLoggedIn], sch) => {
        return isLoggedIn === false
          ? sch
            .trim()
            .test("is-valid-email", t("checkout.invalid_email"), (value) =>
              validateEmail(value || "")
            )
            .required(t("checkout.email_required"))
          : sch.notRequired();
      }),
    selectedAddress: Yup.string().required(t("errors.address")),
    first_name: Yup.string().required(t("errors.firstName")),
    last_name: Yup.string().required(t("errors.lastName")),
    mobile_number: Yup.string()
      .matches(/^5\d{8}$/, t("errors.mobile.invalid"))
      .required(t("errors.mobile.required")),
    address: Yup.string().required(t("errors.address")),
    city: Yup.string().required(t("errors.city")),
    is_want_to_register: Yup.boolean(),
    payment_method_id: Yup.string().required(t("checkout.payment_method")),

  });

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      confirmOrder(values);

    },
  });

  const locationSelected = () => {
    if (searchResult) {
      const place = searchResult.getPlace();
      if (place.geometry?.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setMarkerPosition({ lat, lng });
        setSelectedPlace({ lat, lng });
        fetchAddress(lat, lng);
      }
    }
  };

  function getLocation() {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          if (position.coords.latitude && position.coords.longitude) {
            setSelectedPlace({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setMarkerPosition({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            fetchAddress(position.coords.latitude, position.coords.longitude);
          }
        });
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  useEffect(() => {
    const value = localStorage.getItem("checkoutFormValues");
    const savedFormValues = value ? JSON.parse(value) : null;
    if (isLoaded && showMap && !savedFormValues) {
      getLocation();
    }
  }, [isLoaded, showMap]);

  useEffect(() => {
    const getCity = async () => {
      try {
        const response = await shippingService.getCities();
        if (response && response.status === 200) {
          setCity(response.data);
        } else {
          console.log("error message:-", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCity();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getAddress();
    }
  }, []);



  const fetchAddress = async (lat: number, lng: number) => {
    try {
      if (!lat && !lng) {
        return;

      }
      const response = await axios.get<any>(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${google_place_api}`
      );

      const addressData = response.data.results[0];
      const newCity =
        response.data.results[0].address_components.filter(
          (f: { types: AutocompleteChangeDetails }) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["locality", "political"])
        )[0].long_name || "";
      if (response.data.results.length > 0) {
        formik.setFieldValue("address", addressData.formatted_address);
        formik.setFieldValue("selectedAddress", "New");
        formik.setFieldValue("shipping_id", "");
        setAddress(addressData.formatted_address);
        const isValidCity = city.find(
          (cityName: any) =>
            cityName.city_name.toLowerCase() === newCity.toLowerCase()
        );
        if (isValidCity) {
          formik.setFieldValue("city", newCity);
        } else {
          formik.setFieldValue("city", "");
        }
      } else {
        console.log("Address not found");
      }
    } catch (error) {
      console.log("Error fetching address:", error);
    }
  };

  const handleLoginEmail = async (values: any) => {
    const request = {
      email: `${values.email}`.toLocaleLowerCase(),
      password: values.password,
    };

    try {
      const response: any = await userService.signIn(request);
      const { data } = response;
      if (response && response.status === 200) {
        setOpenDialog(false);
        ShowToast(response.message, "success");
        useAuthStore.getState().setUserInfo(data);
        formik.setFieldValue("email", request.email);
        fetchCheckoutData(coupon);
      } else {
        console.log("error message:- ", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error.message);
    }
  };

  const {
    errors,
    touched,
    values,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    handleChange,
  } = formik;

  if (!checkoutData) {
    return <></>;
  }

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <AppBar
            position="sticky"
            sx={{
              backgroundColor: "#fff",
              color: "#000",
              zIndex: 100,
            }}
            elevation={1}
          >
            <Toolbar sx={{ justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  direction: "ltr",
                  cursor: "pointer",
                }}
                onClick={() => navigate(CurrentLanguagePath(""))}
              >
                <img
                  src={require("../../assets/images/Neeshah-logo.png")}
                  alt="lusive"
                  style={{ height: 38 }}
                />
                {/* <img
                  src={require("../../assets/images/lusive.png")}
                  alt="lusive"
                  style={{ height: 23 }}
                /> */}
              </Box>
            </Toolbar>
          </AppBar>
          <Container
            maxWidth={"xl"}
            sx={{ display: "block", margin: "10px auto", p: "10px" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
            >
              <Typography onClick={() => changeLanguage("en")}>
                English
              </Typography>{" "}
              <Typography sx={{ mx: 1 }}>|</Typography>
              <Typography onClick={() => changeLanguage("ar")}>عربي</Typography>
            </Box>
          </Container>
          <Container
            maxWidth={"lg"}
            sx={{
              position: "relative",
              mt: 1,
              overflow: direction === "rtl" ? "hidden" : "unset",
              padding: "24px !important",
            }}
          >
            <Typography
              variant="h4"
              align="center"
              fontSize={"36px"}
              fontWeight={"400"}
              mt={"2%"}
              sx={{ textTransform: "uppercase" }}
            >
              {t("common.checkout")}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "14.52px",
                textAlign: "center",
              }}
              align="center"
              mb={"15px"}
              color={"#A4A1AA"}
            >
              {t("checkout.required_fields")}
            </Typography>

            <Grid container spacing={3}>
              {/* First Column */}
              <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                  {!isLoggedIn && (
                    <Grid item xs={12}>
                      <Box bgcolor={"#A4A1AA33"}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#131118",
                            color: "#fff",
                            height: "32px",
                            [borderBadge]: "12px",
                          }}
                        >
                          <StyledBadge
                            badgeContent={1}
                            direction={direction}
                            isLogin={isLoggedIn}
                          />
                          <Typography
                            sx={{
                              fontSize: { xs: "12px", md: "16px", lg: "18px" },
                              fontWeight: "500",
                              lineHeight: "21.78px",
                              textAlign: "left",
                            }}
                            color={"#fff"}
                            px={4}
                          >
                            {t("checkout.sign_in")}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            justifyContent: "flex-start",
                            padding: "13px 18px 20px 18px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "300",
                                lineHeight: "16.94px",
                                marginRight: "5px",
                              }}
                            >
                              ● &nbsp;&nbsp;
                            </p>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "300",
                                lineHeight: "16.94px",
                              }}
                            >
                              {t("checkout.registered_account")}&nbsp;
                              <Link
                                style={{ fontWeight: "400", color: "#000" }}
                                to={CurrentLanguagePath(`login`)}
                              >
                                {t("checkout.please_signin")}
                              </Link>
                            </Typography>
                          </div>
                          <Divider
                            sx={{
                              mb: 1,
                            }}
                          >
                            OR
                          </Divider>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              marginBottom: "0px",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "300",
                                lineHeight: "16.94px",
                                marginRight: "5px",
                              }}
                            >
                              ● &nbsp;&nbsp;
                            </p>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "300",
                                lineHeight: "16.94px",
                              }}
                            >
                              {t("checkout.continue_prompt")}
                            </Typography>
                          </div>
                          <Field name="email">
                            {({ field }: FieldProps) => (
                              <TextField
                                fullWidth
                                label={`${t("checkout.email")}*`}
                                margin="normal"
                                size="small"
                                {...field}
                                onChange={handleChange}
                                id="email"
                                onBlur={handleBlur}
                                value={values.email}
                                error={touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={textFieldStyles(direction)}
                              />
                            )}
                          </Field>
                          <FormControlLabel
                            control={
                              <Field name="is_want_to_register">
                                {({ field }: FieldProps) => (
                                  <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onChange={handleChange}
                                    sx={{
                                      padding: "0px",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  />
                                )}
                              </Field>
                            }
                            sx={{
                              width: "100%",
                              marginRight: "0px !important",
                              marginLeft: "0px",
                              ".MuiFormControlLabel-label": {
                                fontSize: "13px",
                                fontWeight: "300",
                                [marginProps]: "5px",
                              },
                            }}
                            label={t("checkout.email_prompt")}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Box bgcolor={"#A4A1AA33"}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#000",
                          color: "#fff",
                          height: "32px",
                          [borderBadge]: "12px",
                        }}
                      >
                        <StyledBadge
                          badgeContent={2}
                          direction={direction}
                          isLogin={isLoggedIn}
                        />
                        <Typography
                          variant="h6"
                          color={"#fff"}
                          px={4}
                          sx={{
                            fontSize: { xs: "12px", md: "16px", lg: "18px" },
                            fontWeight: "500",
                          }}
                        >
                          {t("checkout.shipping_method")}
                        </Typography>
                      </Box>

                      <FormControl component="fieldset" sx={{}}>
                        <RadioGroup>
                          {checkoutData &&
                            checkoutData.shippingMethods &&
                            checkoutData.shippingMethods.map(
                              (method: any, index: number) => (
                                <Box
                                  key={index}
                                  sx={{
                                    padding: "7px 18px 20px 18px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <FormControlLabel
                                    sx={{
                                      mr: "0px",
                                      ".MuiFormControlLabel-label": {
                                        mb: 0,
                                        ml: "5px",
                                      },
                                    }}
                                    value={method.id}
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{ height: "25px", width: "25px" }}
                                        checked
                                      />
                                    }
                                    label={
                                      <Box sx={{ display: "flex" }}>
                                        <Typography
                                          fontSize="14px"
                                          fontWeight="400"
                                        >
                                          {method.heading} &nbsp;
                                        </Typography>
                                        <Typography
                                          fontWeight="300"
                                          fontSize="14px"
                                        >
                                          {method.shipping_method_amount}{" "}
                                          {t("common.sar")}
                                        </Typography>
                                      </Box>
                                    }
                                  />
                                  <Typography
                                    fontSize="12px"
                                    fontWeight="300"
                                    textAlign="center"
                                    ml="20px"
                                  >
                                    {method.sub_heading}
                                  </Typography>
                                </Box>
                              )
                            )}
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box bgcolor={"#A4A1AA33"}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#000",
                          color: "#fff",
                          height: "32px",
                          [borderBadge]: "12px",
                        }}
                      >
                        <StyledBadge
                          badgeContent={3}
                          direction={direction}
                          isLogin={isLoggedIn}
                        />
                        <Typography
                          variant="h6"
                          color={"#fff"}
                          px={4}
                          sx={{
                            fontSize: { xs: "12px", md: "16px", lg: "18px" },
                            fontWeight: "500",
                          }}
                        >
                          {t("checkout.delivery_info")}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          marginBottom: { sm: "50px" },
                          padding: "7px 18px 20px 18px",
                        }}
                      >
                        <Field name="selectedAddress">
                          {({ field }: FieldProps) => (
                            <TextField
                              fullWidth
                              select
                              label={`${t("checkout.address")}*`}
                              placeholder={t("checkout.address")}
                              margin="normal"
                              size="small"
                              {...field}
                              id="address"
                              onBlur={handleBlur}
                              value={values.selectedAddress}
                              error={
                                touched.selectedAddress &&
                                Boolean(errors.selectedAddress)
                              }
                              helperText={
                                touched.selectedAddress &&
                                errors.selectedAddress
                              }
                              sx={textFieldStyles(direction)}
                            >
                              {addresses &&
                                addresses.map((address, index) => (
                                  <MenuItem
                                    key={index}
                                    value={address.address}
                                    onClick={() => {
                                      if (address) {
                                        setShowMap(false);
                                        setFieldValue(
                                          "first_name",
                                          address.first_name
                                        );
                                        setFieldValue(
                                          "last_name",
                                          address.last_name
                                        );
                                        setFieldValue(
                                          "mobile_number",
                                          address.phone
                                        );
                                        setFieldValue(
                                          "address",
                                          address.address
                                        );
                                        setFieldValue("shipping_id", address.id);
                                        setFieldValue("city", address.city);
                                        setFieldValue(
                                          "selectedAddress",
                                          address.address
                                        );
                                        setTimeout(() =>
                                          setFieldTouched("first_name", true)
                                        );
                                        setTimeout(() =>
                                          setFieldTouched("last_name", true)
                                        );
                                        setTimeout(() =>
                                          setFieldTouched("mobile_number", true)
                                        );
                                        setTimeout(() =>
                                          setFieldTouched("address", true)
                                        );
                                        setTimeout(() =>
                                          setFieldTouched(
                                            "selectedAddress",
                                            true
                                          )
                                        );
                                      }
                                    }}
                                  >
                                    {address.address}
                                  </MenuItem>
                                ))}
                              <MenuItem
                                value="New"
                                onClick={() => {
                                  setShowMap(true);
                                  setFieldValue("selectedAddress", "New");
                                  setFieldValue("shipping_id", "");
                                  setFieldValue("first_name", "");
                                  setFieldValue("last_name", "");
                                  setFieldValue("mobile_number", "");
                                  setFieldValue("address", "");
                                  setFieldValue("city", "");
                                }}
                              >
                                {t("checkout.new")}
                              </MenuItem>
                            </TextField>
                          )}
                        </Field>
                        <Field name="first_name">
                          {({ field }: FieldProps) => (
                            <TextField
                              id="first_name"
                              fullWidth
                              label={`${t("checkout.first_name")}*`}
                              margin="normal"
                              size="small"
                              {...field}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.first_name}
                              error={
                                touched.first_name && Boolean(errors.first_name)
                              }
                              helperText={
                                touched.first_name && errors.first_name
                              }
                              sx={textFieldStyles(direction)}
                            />
                          )}
                        </Field>

                        <Field name="last_name">
                          {({ field }: FieldProps) => (
                            <TextField
                              fullWidth
                              id="last_name"
                              label={`${t("checkout.last_name")}*`}
                              margin="normal"
                              size="small"
                              {...field}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.last_name}
                              error={
                                touched.last_name && Boolean(errors.last_name)
                              }
                              helperText={touched.last_name && errors.last_name}
                              sx={textFieldStyles(direction)}
                            />
                          )}
                        </Field>

                        <Field name="mobile_number">
                          {({ field }: FieldProps) => (
                            <TextField
                              id="mobile_number"
                              inputProps={{
                                maxLength: 9,
                                inputMode: "numeric",
                              }}
                              fullWidth
                              label={`${t("checkout.mobile_number")}*`}
                              margin="normal"
                              placeholder="5XXXXXXXX"
                              size="small"
                              {...field}
                              onChange={(event: any) => {
                                setFieldValue(
                                  "mobile_number",
                                  event.target.value.replace(/[^0-9]+/g, "")
                                );
                              }}
                              onBlur={handleBlur}
                              value={values.mobile_number}
                              error={
                                touched.mobile_number &&
                                Boolean(errors.mobile_number)
                              }
                              helperText={
                                touched.mobile_number && errors.mobile_number
                              }
                              sx={textFieldStyles(direction)}
                            />
                          )}
                        </Field>
                        {showMap && (
                          <Box sx={{ marginTop: 2 }}>
                            {isLoaded ? (
                              <>
                                <Autocomplete

                                  onLoad={onLoad}
                                  onPlaceChanged={locationSelected}
                                  options={{
                                    bounds: new google.maps.LatLngBounds(
                                      new google.maps.LatLng(16.0, 34.5),
                                      new google.maps.LatLng(32.0, 55.0)
                                    ),
                                    componentRestrictions: { country: "sa" },
                                  }}
                                >
                                  <TextField
                                    onKeyDown={(e: any) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                      }
                                    }}
                                    fullWidth
                                    label={`${t("checkout.search_place")}*`}
                                    margin="normal"
                                    size="small"
                                    value={address}
                                    onChange={(e) => {
                                      e.preventDefault()
                                      setAddress(e.target.value)
                                    }}
                                    sx={textFieldStyles(direction)}
                                  />
                                </Autocomplete>
                                <div
                                  className="map-container"
                                  style={{ position: "relative" }}
                                >
                                  <GoogleMap
                                    center={selectedPlace || { lat: 0, lng: 0 }}
                                    zoom={15}
                                    mapContainerStyle={{
                                      height: "300px",
                                      width: "100%",
                                    }}
                                    options={{
                                      draggable: true,
                                      scrollwheel: false,
                                      keyboardShortcuts: false,
                                      mapTypeControl: false,
                                      fullscreenControl: false,
                                      zoomControl: true,
                                      streetViewControl: false,
                                      controlSize: 30,
                                    }}
                                  >
                                    {markerPosition && (
                                      <Marker
                                        position={markerPosition}
                                        draggable
                                        onDragEnd={(event) => {
                                          if (event.latLng) {
                                            const lat = event.latLng.lat();
                                            const lng = event.latLng.lng();
                                            setMarkerPosition({ lat, lng });
                                            setSelectedPlace({ lat, lng });
                                            const geocoder =
                                              new window.google.maps.Geocoder();
                                            geocoder.geocode(
                                              { location: { lat, lng } },
                                              (results, status) => {
                                                if (
                                                  status === "OK" &&
                                                  results &&
                                                  results[0]
                                                ) {
                                                  fetchAddress(lat, lng);
                                                  setAddress(
                                                    results[0].formatted_address
                                                  );
                                                }
                                              }
                                            );
                                          }
                                        }}
                                      />
                                    )}
                                  </GoogleMap>
                                  <button
                                    onClick={getLocation}
                                    style={{
                                      position: "absolute",
                                      bottom: "10px",
                                      left: "10px",
                                      backgroundColor: "#fff",
                                      border: "2px solid #fff",
                                      borderRadius: "50%",
                                      boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                                      cursor: "pointer",
                                      padding: "4px",
                                      zIndex: 1,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 48 48"
                                      style={{ width: "20px", height: "20px" }}
                                    >
                                      <path d="M24 16a8 8 0 1 0 0 16 8 8 0 1 0 0-16zm17.88 6A17.99 17.99 0 0 0 26 6.12V2h-4v4.12A17.99 17.99 0 0 0 6.12 22H2v4h4.12A17.99 17.99 0 0 0 22 41.88V46h4v-4.12A17.99 17.99 0 0 0 41.88 26H46v-4h-4.12zM24 38c-7.73 0-14-6.27-14-14s6.27-14 14-14 14 6.27 14 14-6.27 14-14 14z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <Field name="address">
                                  {({ field }: FieldProps) => (
                                    <TextField
                                      id="address"
                                      fullWidth
                                      label={`${t("checkout.address")}*`}
                                      margin="normal"
                                      size="small"
                                      {...field}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.address}
                                      error={
                                        touched.address &&
                                        Boolean(errors.address)
                                      }
                                      helperText={
                                        touched.address && errors.address
                                      }
                                      sx={textFieldStyles(direction)}
                                    />
                                  )}
                                </Field>

                                <Field name="city">
                                  {({ field }: FieldProps) => (
                                    <TextField
                                      fullWidth
                                      select
                                      label={`${t("checkout.city")}*`}
                                      margin="normal"
                                      id="city"
                                      size="small"
                                      {...field}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.city}
                                      error={
                                        touched.city && Boolean(errors.city)
                                      }
                                      helperText={touched.city && errors.city}
                                      sx={textFieldStyles(direction)}
                                    >
                                      {city.map((city, index) => (
                                        <MenuItem
                                          key={index}
                                          value={city.city_name}
                                        >
                                          {city.city_name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  )}
                                </Field>
                              </>
                            ) : (
                              <p>Loading...</p>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              {/* Second Column */}
              <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box bgcolor={"#A4A1AA33"}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#000",
                          color: "#fff",
                          height: "32px",
                          [borderBadge]: "12px",
                        }}
                      >
                        <StyledBadge
                          badgeContent={4}
                          direction={direction}
                          isLogin={isLoggedIn}
                        />
                        <Typography
                          variant="h6"
                          color={"#fff"}
                          px={4}
                          fontSize={"18px"}
                          sx={{
                            fontSize: { xs: "12px", md: "16px", lg: "18px" },
                            fontWeight: "500",
                          }}
                        >
                          {t("checkout.coupon_and_promotions")}
                        </Typography>
                      </Box>
                      <Box sx={{ padding: "7px 18px 20px 18px" }}>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "500",
                            lineHeight: "27.68px",
                            textAlign: direction === "rtl" ? "right" : "left",
                          }}
                        >
                          {t("checkout.promo_codes")}
                        </Typography>
                        {/* <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "17.34px",
                            textAlign: direction === "rtl" ? "right" : "left",
                            color: COLORS.COPPER_ORANGE,
                            mb: "3px",
                          }}
                        >
                          {t("checkout.use_code")}{" "}
                          <span style={{ fontWeight: "500" }}>MAY</span>{" "}
                          {t("checkout.get_the_offer")}
                        </Typography> */}
                        <CouponInputBox sx={{ mb: "0px", pb: "0px" }}>
                          <TextField
                            variant="outlined"
                            size="small"
                            value={coupon}
                            onChange={handleCouponChange}
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                color: "#000",
                                mt: "3px",
                                mb: 2,
                                height: "33px",
                                borderRadius: "2px",
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "17.34px",
                                backgroundColor: "#fff",
                                overflow: "hidden",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#000",
                                  borderWidth: ".5px",
                                },
                              },
                              "&.Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "0px solid #000",
                                  borderRadius: "0px",
                                },
                              },
                            }}
                            inputProps={{
                              readOnly: Boolean(isCouponApplied),
                              disabled: Boolean(isCouponApplied),
                            }}
                            placeholder={t("checkout.enter_promo_code")}
                            InputProps={{
                              endAdornment: isCouponApplied && (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClearCoupon}
                                    edge="end"
                                    sx={{ padding: "0px" }}
                                  >
                                    <Close
                                      sx={{
                                        fontSize: "16px",
                                        color: "#fff",
                                        backgroundColor: "black",
                                        padding: "2px",
                                        borderRadius: "8px",
                                        mx: "5px",
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Button
                            onClick={() => {
                              applyCoupon();

                            }}
                            variant="outlined"
                            disabled={isCouponApplied}
                            sx={{
                              ml: direction === "rtl" ? 3 : 1,
                              mt: "3px",
                              mb: 2,
                              mr: direction === "rtl" ? 1 : 5,
                              width: "79px",
                              border: "1px solid #131118",
                              padding: "1px",
                              height: "33px",
                              borderRadius: "2px",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "20.23px",
                              textAlign: "center",
                              backgroundColor: "#fff",
                              textTransform: "capitalize",
                              "&.Mui-disabled": {
                                color: "#000",
                                opacity: 0.7,
                              },
                            }}
                          >
                            {!isCouponApplied
                              ? t("cart.apply")
                              : t("cart.applied")}
                          </Button>
                        </CouponInputBox>
                        {appliedCoupon && (
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "17.34px",
                              textAlign: direction === "rtl" ? "right" : "left",
                              mb: "3px",
                              mt: "-8px",
                              color: "green",
                            }}
                          >
                            {appliedCoupon}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box bgcolor={"#A4A1AA33"}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#000",
                          color: "#fff",
                          height: "32px",
                          [borderBadge]: "12px",
                        }}
                      >
                        <StyledBadge
                          badgeContent={5}
                          direction={direction}
                          isLogin={isLoggedIn}
                        />
                        <Typography
                          variant="h6"
                          color={"#fff"}
                          px={4}
                          fontSize={"18px"}
                          sx={{
                            fontSize: { xs: "12px", md: "16px", lg: "18px" },
                            fontWeight: "500",
                          }}
                        >
                          {t("checkout.payment_info")}
                        </Typography>
                      </Box>
                      <Box sx={{ padding: "7px 18px 20px 18px" }}>
                        <Field name="payment_method_id">
                          {({ field, form }: FieldProps) => (
                            <FormControl component="fieldset" sx={{}}>
                              <RadioGroup
                                name="paymentMethod"
                                value={field.value}
                                onChange={(event) =>
                                  form.setFieldValue(
                                    "payment_method_id",
                                    event.target.value
                                  )
                                }
                              >
                                {checkoutData &&
                                  checkoutData.paymentMethods.map(
                                    (method, index) => (
                                      <Box
                                        key={index}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          ml: "8px",
                                        }}
                                      >
                                        <FormControlLabel
                                          sx={{
                                            ".MuiFormControlLabel-label": {
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              mb: 0,
                                              ml: "4px",
                                            },
                                          }}
                                          value={method.payment_method}
                                          control={
                                            <Radio
                                              size="small"
                                              sx={{
                                                height: "25px",
                                                width: "25px",
                                              }}
                                            />
                                          }
                                          label={method.name}
                                        />
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center"
                                          }}
                                        >
                                          {method.icons.map((image, index) => (
                                            <Box
                                              key={index}
                                              component="img"
                                              src={image}
                                              width="40px"
                                              height="100%"
                                              alt={method.name}
                                              sx={{
                                                marginRight:
                                                  direction === "rtl"
                                                    ? "15px"
                                                    : "unset",
                                              }}
                                            />
                                          ))}
                                        </Box>
                                      </Box>
                                    )
                                  )}
                              </RadioGroup>
                              {form.touched.payment_method_id &&
                                form.errors.payment_method_id ? (
                                <FormHelperText error>
                                  {form.errors.payment_method_id.toString()}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              {/* Third Column */}
              <Grid item xs={12} md={4}>
                <Box bgcolor={"#A4A1AA33"} sx={{ marginBottom: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#000",
                      color: "#fff",
                      height: "32px",
                      [borderBadge]: "12px",
                    }}
                  >
                    <StyledBadge
                      badgeContent={6}
                      direction={direction}
                      isLogin={isLoggedIn}
                    />
                    <Typography
                      variant="h6"
                      color={"#fff"}
                      px={4}
                      fontSize={"18px"}
                      sx={{
                        fontSize: { xs: "12px", md: "16px", lg: "18px" },
                        fontWeight: "500",
                      }}
                    >
                      {t("checkout.order_overview")}
                    </Typography>
                  </Box>
                  <Box sx={{ padding: "7px 18px 20px 18px" }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={"center"}
                      marginBottom={2}
                    >
                      <img src={imagepath.delivery} alt="Delivery" width="30" />
                      <Typography
                        component="p"
                        sx={{
                          marginLeft: 1,
                          color: "#018849",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "17.34px",
                          textAlign: "left",
                        }}
                      >
                        {t("checkout.estimated_delivery")}
                      </Typography>
                    </Box>

                    {checkoutData &&
                      checkoutData?.cart_items?.map((product, index) => (
                        <Box
                          display="flex"
                          marginTop={2}
                          key={index}
                          sx={{ alignItems: "center" }}
                        >
                          <img
                            src={product?.product_image_url}
                            alt={product?.product_name}
                            width="92px"
                            style={{ objectFit: "cover", height: "144px" }}
                          />
                          <Box sx={{ [marginProps]: 2 }}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "17.12px",
                              }}
                              component="p"
                            >
                              {product?.vendor_name}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "17.12px",
                                // textAlign: "left",
                              }}
                              component="p"
                            >
                              {product?.product_name}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "300",
                                lineHeight: "23.12px",
                                // textAlign: "left",
                              }}
                              component="p"
                            >
                              {product?.discount_label}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "500",
                                lineHeight: "15.9px",
                                // textAlign: "left",
                                mt: "4px",
                              }}
                              component="p"
                            >
                              {t("common.color")}: {product?.color_name}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "500",
                                lineHeight: "15.9px",
                                // textAlign: "left",
                              }}
                              component="p"
                            >
                              {t("common.size")}: {product?.size}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "500",
                                lineHeight: "15.9px",
                                // textAlign: "left",
                              }}
                              component="p"
                            >
                              {t("common.quantity")}: {product?.quantity}
                            </Typography>
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "20.23px",
                                  // textAlign: "left",
                                  mt: 1,
                                  textDecoration:
                                    product?.sale_price && "line-through",
                                }}
                              >
                                {product?.price} {t("common.sar")}
                              </Typography>
                              {product?.sale_price && (
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "20.23px",
                                    // textAlign: "left",
                                    mt: 1,
                                    color: "#E51C1C",
                                  }}
                                >
                                  &nbsp; {product?.sale_price} {t("common.sar")}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                </Box>
                {checkoutData && checkoutData.subtotal && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    marginBottom={1}
                  >
                    <Typography variant="body2" fontWeight={"300"}>
                      {t("checkout.sub_total")}:
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "300" }}>
                      {checkoutData.subtotal} {t("common.sar")}
                    </Typography>
                  </Box>
                )}

                {checkoutData &&
                  checkoutData.discountedAmount &&
                  Number(checkoutData.discountedAmount) > 0 && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mb: "4px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "20px",
                            textAlign: "left",
                            color: "#E51C1C",
                          }}
                        >
                          {t("cart.discount")}:
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "20px",
                            textAlign: "left",
                            color: "#E51C1C",
                          }}
                        >
                          -{checkoutData?.discountedAmount} {t("common.sar")}
                        </Typography>
                      </Box>
                    </>
                  )}

                {checkoutData && checkoutData.shippingAmount && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    marginBottom={1}
                  >
                    <Typography variant="body2" fontWeight={"300"}>
                      {t("checkout.shipping")}:
                    </Typography>
                    <div>
                      {Number(checkoutData.shippingAmount) === 0 && (
                        <div style={{ display: "flex" }}>
                          {Number(checkoutData.default_shipping_price) > 0 && <Typography
                            variant="body2"
                            sx={{
                              fontWeight: "300",
                              textDecoration: "line-through",
                            }}
                          >
                            {checkoutData.default_shipping_price}{" "}
                            {t("common.sar")}
                          </Typography>}
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "300", color: "#E51C1C" }}
                          >
                            &nbsp;&nbsp;{checkoutData?.shippingAmount}{" "}
                            {t("common.sar")}
                          </Typography>
                        </div>
                      )}
                      {Number(checkoutData.shippingAmount) > 0 && (
                        <Typography variant="body2" sx={{ fontWeight: "300" }}>
                          {checkoutData.shippingAmount} {t("common.sar")}
                        </Typography>
                      )}
                    </div>
                  </Box>
                )}

                {checkoutData && Number(checkoutData.taxAmount) > 0 && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    marginBottom={1}
                  >
                    <Typography variant="body2" fontWeight={"300"}>
                      {t("checkout.tax")}:
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "300" }}>
                      {checkoutData.taxAmount} {t("common.sar")}
                    </Typography>
                  </Box>
                )}

                <Divider
                  sx={{ marginBottom: 2 }}
                  style={{ backgroundColor: COLORS.BLACK }}
                />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginBottom={1}
                >
                  <Typography
                    variant="body2"
                    fontSize={"14px"}
                    fontWeight={"500"}
                  >
                    {t("checkout.grand_total")}:
                  </Typography>
                  <Typography
                    variant="body2"
                    fontSize={"14px"}
                    fontWeight={"500"}
                  >
                    {checkoutData?.grandtotal} {t("common.sar")}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  sx={{
                    borderRadius: "0px",
                    textTransform: "capitalize",
                    marginBottom: { xs: "20px" },
                    mt: 1,
                    backgroundColor: COLORS.BTN_BLACK,
                    width: "100%",
                    height: "41px",
                  }}
                >
                  {t("checkout.checkout_now")}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </form>
        <LoginDialog
          text={t("checkout.email_exist")}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          handleLogin={handleLoginEmail}
        />
        <FormikErrorFocus focusDelay={100} formik={formik as any} offset={-100} duration={100} align="top" ease={"linear"} />
      </FormikProvider>
    </div>
  );
};

export default Checkout;
