import React, { useEffect } from "react";
import "./App.css";
import { Box, useTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import AppRoutes from "./routes";
import {} from "@emotion/react";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { RouterProvider} from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./utils/i18n";
import { themeLTR, themeRTL } from "./utils/theme/theme";
import { ToastContainer } from "react-toastify";
import useAuthStore from "./zustand/store/user";
import Loader from "./components/Loader";
import { useLanguageStore } from "./zustand/store/language";
import { supportedLanguages } from "./constant/baseUrl";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import "react-lazy-load-image-component/src/effects/blur.css";

const tagManagerArgs = {
  gtmId: "GTM-5639X6HM",
};
TagManager.initialize(tagManagerArgs);

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { init, isLoading }: any = useAuthStore();
  const setLanguage = useLanguageStore((state) => state.setLanguage);
  const language = useLanguageStore((state) => state.language);
  const [isTabVisible, setIsTabVisible] = React.useState(true);
  const { pathname } = window.location;
  const [metadata, setMetadata] = React.useState({
    title: "",
    description:
      "",
    image: "",
    keywords: "",
  });
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const pathSegments = pathname.split("/").filter(Boolean);
        const currentLang = pathSegments[0];
        const languageFromUrl = supportedLanguages.includes(currentLang)
          ? currentLang
          : null;
        const storedLanguage = localStorage.getItem("i18nextLng") || "ar";
        if (languageFromUrl !== storedLanguage) {
          const url = new URL(window.location.href);
          const pathSegments = url.pathname.split("/");
          if (pathSegments.length > 1) {
            pathSegments[1] = storedLanguage;
          }

          url.pathname = pathSegments.join("/");
          window.history.pushState({}, "", url.toString());
        }

        setIsTabVisible(true);
      } else {
        setIsTabVisible(false);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [pathname]);

  useEffect(() => {
    useAuthStore.setState({ isMobile: isMobile });
  }, [isMobile]);

  function updateLanguage(languageToUse: string) {
    i18n.changeLanguage(languageToUse);
    setLanguage(languageToUse);
    document.documentElement.dir = languageToUse === "ar" ? "rtl" : "ltr";
    document.body.setAttribute("dir", languageToUse === "ar" ? "rtl" : "ltr");
  }

  useEffect(() => {
    init();
    const pathSegments = pathname.split("/").filter(Boolean);
    const currentLang = pathSegments[0];
    const languageFromUrl = supportedLanguages.includes(currentLang)
      ? currentLang
      : null;
    const storedLanguage = localStorage.getItem("i18nextLng");
    const languageToUse = languageFromUrl || storedLanguage || "ar";
    updateLanguage(languageToUse);
  }, [i18n.language, setLanguage, pathname]);

  useEffect(() => {
    const fetchMetadata = async () => {
      const urlPath = window.location.pathname;
      const queryParams = window.location.search;
      const pathSegments = urlPath.split("/").filter((segment) => segment);
      const supportedLanguages = ["en", "ar"];
      let lng = "en";

      if (pathSegments.length > 0 && supportedLanguages.includes(pathSegments[0])) {
        lng = pathSegments.shift() || "en";
      }

      const adjustedPath = `/${pathSegments.join("/")}`;
      const fullUrlWithQuery = queryParams
        ? `${adjustedPath}${queryParams}`
        : adjustedPath;
      const myHeaders = {
        lng: lng,
        "Content-Type": "application/json",
      };
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ url: fullUrlWithQuery }),
      };

      if (adjustedPath.toLowerCase() !== "/logo192.png".toLowerCase()) {
        try {
          const response = await fetch("https://lusiveksa-api.y2code.com/api/getMeta", requestOptions);
          if (response.ok) {
            const data = await response.json();
            if (data && data.data) {
              setMetadata(data.data);
            }
          } else {
            console.error(`HTTP error! Status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching metadata:", error);
        }
      }
    };

    fetchMetadata();
  }, [window.location.pathname, window.location.search]);



  return (
    <ThemeProvider theme={language === "en" ? themeLTR : themeRTL}>
      <I18nextProvider i18n={i18n}>
        <Box id={"mainWrapper"}>
          {isLoading && <Loader />}
          <Helmet>
            <title>{metadata.title}</title>
            <meta name="description" content={metadata.description} />
            <meta name="keywords" content={metadata.keywords} />
            <meta name="og:title" content={metadata.title} />
            <meta name="og:description" content={metadata.description} />
            <meta name="og:image" content={metadata.image} />
          </Helmet>
          <RouterProvider router={AppRoutes()} />
          <ToastContainer />
        </Box>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
