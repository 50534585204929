import Carousel from "react-multi-carousel";
import { Box, Container, Link, useMediaQuery } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import { useTranslation } from "react-i18next";
import SliderSkeleton from "./SliderShimmer";
import { CurrentLanguagePath } from "../utils/helper";
import React from "react";
import { ViewBanner } from "../tagManager/gtag";
interface SliderItem {
  id: number;
  slug: string;
  src: string;
}

interface SlickSliderProps {
  sliderItems: SliderItem[];
  height?: string;
  onSliderClick?: (item: SliderItem) => void;
}

const SlickSlider = ({
  sliderItems,
  height,
  onSliderClick,
}: SlickSliderProps) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const { i18n } = useTranslation();
  const dir = i18n.dir();
  const dotSize = isMobile ? 13 : 15;
  const borderRadius = dotSize / 2;
  const CustomDot = ({ onClick, active }: any) => {
    return (
      <li
        style={{
          display: "inline-block",
          margin: "0 3px",
          cursor: "pointer",
        }}
      >
        <div
          onClick={onClick}
          style={{
            background: active ? "#fff" : "grey",
            borderRadius: `${borderRadius}px`,
            width: `${dotSize}px`,
            height: `${dotSize}px`,
            border: "none",
            WebkitAppearance: "none",
            cursor: "pointer",
            display: "inline-block",
          }}
        />
      </li>
    );
  };

  const showDots = sliderItems.length > 1;
  const [clientXonMouseDown, setClientXonMouseDown] = React.useState<
    number | null
  >(null);
  const [clientYonMouseDown, setClientYonMouseDown] = React.useState<
    number | null
  >(null);
  const handleOnMouseDown = (e: React.MouseEvent) => {
    setClientXonMouseDown(e.clientX);
    setClientYonMouseDown(e.clientY);
    e.preventDefault();
  };

  const handleOnClick = (e: React.MouseEvent, item: SliderItem) => {
    e.stopPropagation();
    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      e.preventDefault();
    }
    ViewBanner({
      bannertype: "Advertisement Banner",
      bannerdata: item,
    });
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: isMobile ? 0 : "0 0px",
        position: "relative",
        margin: { xs: "0", sm: "0 auto" },
        width: { xs: "100%", sm: "90%" },
        "& .react-multi-carousel-list.container": {
          paddingX: "0px",
        },
      }}
    >
      <Carousel
        beforeChange={() => { }}
        customDot={showDots ? <CustomDot /> : null}
        additionalTransfrom={0}
        arrows={false}
        autoPlaySpeed={3000}
        autoPlay={sliderItems.length > 1 ? true : false}
        centerMode={false}
        className=""
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={sliderItems.length > 1 ? true : false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 1,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 1,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={dir === "rtl"}
        shouldResetAutoplay
        showDots={showDots}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {sliderItems.length <= 0 ? (
          <SliderSkeleton numberOfItems={1} />
        ) : (
          sliderItems.map((item: SliderItem, index: number) => (
            <Link
              key={index}
              component={"a"}
              href={CurrentLanguagePath(`products/slider/${item.slug}`)}
              onMouseDown={handleOnMouseDown}
              onClick={(e) => handleOnClick(e, item)}
              draggable={false}
            >
              <Box
                key={item.id}
                sx={{
                  position: "relative",
                  display: "flex !important",
                  justifyContent: "center !important",
                  objectFit: "contain",
                }}
              >
                <img
                  draggable="false"
                  src={item.src}
                  alt={item.slug}
                  style={{
                    width: "100%",
                    minHeight: "190px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Link>
          ))
        )}
      </Carousel>
    </Container>
  );
};

export default SlickSlider;
