import {
  Box,
  IconButton,
  useTheme,
  Container,
  useMediaQuery,
  Grid,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-multi-carousel";
import { ArrowBackIos, ArrowForwardIosOutlined } from "@mui/icons-material";
import ProductCard from "./productCard";
import { StyledTypography } from "../utils/theme/styles/Common";
import { useTranslation } from "react-i18next";
import { Products } from "../utils/type";
import ProductSkeleton from "./ProductShimmer";
import { CurrentLanguagePath } from "../utils/helper";
import { Link, Outlet } from "react-router-dom";

type ProductCarouselProps = {
  products: Products[];
  title?: string | undefined;
  seeAll?: boolean;
  marginTop?: number;
  slug?: string;
  handleWishlist?: (index: number) => void;
};

const MySlider = ({
  products,
  title,
  seeAll,
  marginTop,
  slug,
  handleWishlist,
}: ProductCarouselProps) => {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const dir = i18n.dir();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const numberOfSkeletons = isMobile ? 3 : isTablet ? 4 : 5;

  const ButtonGroup = ({ next, previous, goToSlide, carouselState }: any) => {
    const { currentSlide, totalItems } = carouselState;
    const visibleItems = carouselSettings.responsive.desktop.items;
    const showPrev = currentSlide > 0;
    const showNext = currentSlide < totalItems - visibleItems;
    return (
      <div
        className="carousel-button-group mb-4  gap-4 flex justify-end 
        items-center w-full"
      >
        {showPrev && (
          <IconButton
            onClick={() => previous()}
            disableFocusRipple
            disableTouchRipple
            disableRipple
            sx={{
              [dir === "rtl" ? "right" : "left"]: "-5px",
              position: "absolute",
              top: "40%",
              zIndex: 10,
              padding: "0px !important",
              color: "#000 !important",
              display: { xs: "none", sm: "block", lg: "block" },
              "& .css-1j3sv1k-MuiSvgIcon-root": {
                fill: "#000 !important",
                color: "#000 !important",
              },
            }}
          >
            <ArrowBackIos className="rtl-transform" />
          </IconButton>
        )}
        {showNext && (
          <IconButton
            onClick={() => next()}
            disableFocusRipple
            disableTouchRipple
            disableRipple
            sx={{
              [dir === "ltr" ? "right" : "left"]: "-5px",
              position: "absolute",
              top: "40%",
              zIndex: 10,
              padding: "0px !important",
              color: "#000 !important",
              display: { xs: "none", sm: "block", lg: "block" },
              "& .css-1j3sv1k-MuiSvgIcon-root": {
                fill: "#000 !important",
                color: "#000 !important",
              },
            }}
          >
            <ArrowForwardIosOutlined className="rtl-transform" />
          </IconButton>
        )}
      </div>
    );
  };

  const carouselSettings = {
    infinite: true,
    responsive: {
      desktop: { breakpoint: { max: 3000, min: 1024 }, items: 5 },
      tablet: { breakpoint: { max: 1024, min: 464 }, items: 4 },
      mobile: { breakpoint: { max: 464, min: 0 }, items: 3 },
    },
    rtl: dir === "rtl",
    ssr: true,
    deviceType: "desktop",
    itemClass: "image-item",
    arrows: false,
    renderButtonGroupOutside: true,
    customButtonGroup: <ButtonGroup />,
    swipeable: true,
    draggable: true,
    focusOnSelect: false,
    keyBoardControl: true,
    minimumTouchDrag: 80,
    pauseOnHover: true,
  };

  return (
    <Container
      maxWidth={"lg"}
      sx={{
        padding: isMobile ? 1 : "16",
        paddingTop: marginTop ?? "50px",
        paddingBottom: isMobile ? 1 : "0px",
        position: "relative",
      }}
    >
      {title && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
            px: "8px",
          }}
        >
          <StyledTypography variant="h6">{title}</StyledTypography>
          {seeAll && (
            <Link
              to={CurrentLanguagePath(`products/${slug}`)}
              style={{ fontSize: "16px", color: "#000" }}
            >
              {t("home.viewAll")}
            </Link>
          )}
        </Box>
      )}
      <div dir={dir}>
        {!isMobile && (
          <>
            {products.length === 0 ? (
              <Outlet />
            ) : (
              <Carousel {...carouselSettings}>
                {products.map((item, index) => (
                  <div key={index}>
                    <ProductCard
                      data={item}
                      handleWishlist={() =>
                        handleWishlist && handleWishlist(index)
                      }
                    />
                  </div>
                ))}
              </Carousel>
            )}
            {products.length === 0 && (
              <Carousel {...carouselSettings}>
                <ProductSkeleton numberOfItems={5} />
              </Carousel>
            )}
          </>
        )}
        <Grid container className="product-list-container">
          {isMobile && (
            <Grid
              container
              item
              xs={12}
              className="product-list"
              wrap="nowrap"
              sx={{ p: 0 }}
            >
              {products.length === 0 ? (
                <ProductSkeleton numberOfItems={numberOfSkeletons} />
              ) : (
                products.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    className="product-item"
                    dir={dir}
                    xs={12}
                  >
                    <ProductCard
                      data={item}
                      handleWishlist={() => {
                        handleWishlist && handleWishlist(index);
                      }}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </Container>
  );
};

export default MySlider;
