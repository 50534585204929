import { v4 as uuidv4 } from "uuid";
import { useLanguageStore } from "../zustand/store/language";
const getSessionId = () => {
  let sessionId = localStorage.getItem("session_id");
  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem("session_id", sessionId);
  }
  return sessionId;
};

const getLanguageFromLocalStorage = () => {
  let language = localStorage.getItem("i18nextLng") || "ar";
  return language === "en" ? "en" : "ar";
};

export const CurrentLanguagePath = (path?: string) => {
  const language = useLanguageStore.getState().language;
  const currentLanguage = language || "ar";
  return path ? `/${currentLanguage}/${path}` : `/${currentLanguage}/`;
};

const setLanguageInLocalStorage = (lng: string) => {
  localStorage.setItem("i18nextLng", lng);
};

const google_place_api = "AIzaSyDiwNjT1ZDkay5P7MPnWmPPqKzbLIG_5wg";

export {
  google_place_api,
  getSessionId,
  getLanguageFromLocalStorage,
  setLanguageInLocalStorage,
};
