import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translateEN from "./languages/en.json";
import translateAR from "./languages/ar.json";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  getLanguageFromLocalStorage,
  setLanguageInLocalStorage,
} from "./helper";
import { useLanguageStore } from "../zustand/store/language";

const resources = {
  en: {
    translation: translateEN,
  },
  ar: {
    translation: translateAR,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ar"],
    resources,
    fallbackLng: "ar",
    lng: getLanguageFromLocalStorage(),
    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"],
      cookieMinutes: 10,
      cookieDomain: "myDomain",
      htmlTag: document.documentElement,
      cookieOptions: { path: "/", sameSite: "strict" },
    },
    react: {
      useSuspense: false,
    },
  });

i18n.on("languageChanged", (lng) => {
  document.documentElement.dir = i18n.dir(lng);
  useLanguageStore.getState().setLanguage(lng);
  setLanguageInLocalStorage(lng);
});

export default i18n;
