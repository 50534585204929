import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleGoBack = () => {
        navigate("/"); 
    };

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "#f5f5f5",
                padding: 2,
            }}
        >
            <Typography variant="h1" sx={{ fontSize: "6rem", fontWeight: "bold", color: "#000" }}>
                404
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, color: "#757575",fontSize:"24px" }}>
                {t("pageNotFound.title")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, color: "#9e9e9e",fontSize:"20px" }}>
               {t("pageNotFound.description")}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleGoBack}>
                {t("pageNotFound.goBack")}
            </Button>
        </Box>
    );
};

export default PageNotFound;
