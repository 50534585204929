import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useParams } from "react-router-dom";
import { blogService } from "../../api/services/blogService";
import HtmlParser from "react-html-parser";
import { Share } from "@mui/icons-material";
import SharePopUp from "../../components/SharePopUp";

const BlogDetails: React.FC = () => {
  const { blogId } = useParams();
  const [blog, setBlog] = useState<any>(null);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const fetchBlog = async () => {
    try {
      const response = await blogService.getBlogDetails(blogId || "");
      if (response && response.status === 200) {
        setBlog(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchBlog();
  }, [blogId]);

  if (!blog)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flex: 1,
        }}
      ></div>
    );

  return (
    <div style={{ minHeight: "100vh" }}>
      <Box sx={{ px: "10px" }}>
        <Container>
          <Box sx={{ my: 3 }}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Roboto",
                fontSize: { sm: "30px", xs: "20px" },
                fontWeight: "900",
                color: "#292b2c",
              }}
              component="h1"
              gutterBottom
            >
              {HtmlParser(blog.title)}
            </Typography>
            <Grid
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
              spacing={2}
            >
              <CalendarMonthIcon
                sx={{ mr: 0.5, color: "#687188" }}
                fontSize="small"
              />
              <Typography variant="body2" color="#687188">
                {HtmlParser(blog.date)}
              </Typography>
              <Share sx={{ color: "#687188", cursor: "pointer" }} fontSize="small" onClick={() => setOpenPopup(true)} />
            </Grid>
          </Box>
          <Box
            component="img"
            loading="lazy"
            alt="blog-image"
            src={blog.image}
            sx={{
              width: "100%",
              height: { xs: "200px", sm: "400px" },
              objectFit: "cover",
            }}
          />
          <Box sx={{ my: "30px" }}>
            <div>
              {<div dangerouslySetInnerHTML={{ __html: blog.description }} />}
            </div>
          </Box>
        </Container>
      </Box>
      <SharePopUp
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        productUrl={window.location.href}
        productName={HtmlParser(blog.title) as any}
      />
    </div>
  );
};

export default BlogDetails;
