import React, { Suspense } from "react";
import {
  ScrollRestoration,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  useLocation,
  Navigate,
  
} from "react-router-dom";
import { Route } from "react-router-dom";
import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import Home from "../pages/home";
import ForgotPassword from "../pages/auth/forgotPassword";
import MobileOTP from "../pages/auth/mobileOTP";
import ProductDetails from "../pages/productDetails";
import Cart from "../pages/cart";
import Header from "../layout/Header";
import Footer from "../components/Footer";
import Checkout from "../pages/checkout";
import OrderConfirmation from "../pages/orderConfirmation";
import ProductListing from "../pages/shop";
import { useMediaQuery, useTheme } from "@mui/material";
import UserProfile from "../pages/userProfile";
import Address from "../pages/address/myAddresses";
import OrderList from "../pages/order/orderlist";
import Account from "../pages/account";
import OrderDetails from "../pages/order/orderDetails";
import NewAddress from "../pages/address/addAddress";
import FAQ from "../pages/faq";
import AboutUs from "../pages/aboutUs";
import ReturnPolicy from "../pages/returnPolicy";
import ContactUs from "../pages/contactUs";
import Wishlist from "../pages/wishlist";
import PrivateRoutes from "./ProtectedRoute";
import AuthRoutes from "./AuthRoute";
import ChangePassword from "../pages/auth/changePassword";
import { useLanguageStore } from "../zustand/store/language";
import { supportedLanguages } from "../constant/baseUrl";
import ShippingPolicy from "../pages/importantLinks/ShippingPolicy";
import PrivacyPolicy from "../pages/importantLinks/PrivacyPolicy";
import TermsConditions from "../pages/importantLinks/Terms&Conditions";
import JoinUs from "../pages/importantLinks/JoinUs";
import JoinUsSuccess from "../pages/importantLinks/JoinUs/JoinSuccess";
import ReviewOrderPage from "../components/ReviewOrderPage";
import Blog from "../pages/blogs/BlogCard";
import BlogDetails from "../pages/blogs/BlogDetails";
import Loader from "../components/Loader";
import ReviewOrderItems from "../components/ReviewOrderItems";
import PageNotFound from "../components/PageNotFound";

const Layout = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const pathWithoutLang = pathname.replace(/^\/[^/]+/, "");

  return (
    <div>
      <Header />
      <Outlet />
      {!pathWithoutLang.startsWith("/products") && <ScrollRestoration />}
      <div>
        {(pathWithoutLang.includes("/product-details") && isSmallScreen) ||
          pathWithoutLang === "/cart" ||
          pathWithoutLang === "/join-us" ? null : (
          <Footer />
        )}
      </div>
    </div>
  );
};

const LanguageRedirect = () => {
  const { language } = useLanguageStore();
  const { pathname } = useLocation();
  const defaultLanguage = "ar";
  const segments = pathname.split("/").filter(Boolean);
  const currentLanguage = segments[0];

  if (pathname === "/") {
    return <Navigate to={`/${language}/`} />;
  }
  if (!supportedLanguages.includes(currentLanguage)) {
    const restOfPath = pathname.slice(currentLanguage.length + 1);
    const newPathname = `/${defaultLanguage}`;
    return <Navigate to={newPathname} />;
  }
  return <Outlet />;
};

const AppRoutes = () => {
  return createBrowserRouter(
  createRoutesFromElements(
      <Route element={<LanguageRedirect />}>
        <Route path="/:lang">
          <Route path="/:lang" element={<Layout />}>
            <Route element={<PrivateRoutes />}>
              <Route
                path="account"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <Account />
                  </Suspense>
                }
              />
              <Route
                path="account/profile"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <UserProfile />
                  </Suspense>
                }
              />
              <Route
                path="account/address"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <Address />
                  </Suspense>
                }
              />
              <Route
                path="account/address/new"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <NewAddress />
                  </Suspense>
                }
              />
              <Route
                path="account/address/edit/:id"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <NewAddress />
                  </Suspense>
                }
              />
              <Route
                path="account/orders"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <OrderList />
                  </Suspense>
                }
              />
              <Route
                path="account/orders/:orderId/order_details"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <OrderDetails />
                  </Suspense>
                }
              />
              <Route
                path="account/orders/:orderId/review_order"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <ReviewOrderPage />
                  </Suspense>
                }
              />
              <Route
                path="wishlist"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <Wishlist />
                  </Suspense>
                }
              />
            </Route>

            <Route index element={<Home />} />
            <Route
              path="product-details/:product_slug"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <ProductDetails />
                </Suspense>
              }
            />
            <Route
              path="cart"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <Cart />
                </Suspense>
              }
            />
            <Route
              path="products"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <ProductListing />
                </Suspense>
              }
            />
            <Route
              path="products/:category"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <ProductListing />
                </Suspense>
              }
            />
            <Route
              path="products/:category/:subcategory"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <ProductListing />
                </Suspense>
              }
            />
            <Route
              path="faq"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <FAQ />
                </Suspense>
              }
            />
            <Route
              path="about-us"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <AboutUs />
                </Suspense>
              }
            />
            <Route
              path="return-policy"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <ReturnPolicy />
                </Suspense>
              }
            />
            <Route
              path="contact_us"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <ContactUs />
                </Suspense>
              }
            />
            <Route
              path="shipping-policy"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <ShippingPolicy />
                </Suspense>
              }
            />
            <Route
              path="privacy-policy"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path="terms-and-conditions"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <TermsConditions />
                </Suspense>
              }
            />
            <Route
              path="join-us"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <JoinUs />
                </Suspense>
              }
            />
            <Route
              path="blogs"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <Blog />
                </Suspense>
              }
            />
            <Route
              path="blogs/:blogId"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <BlogDetails />
                </Suspense>
              }
            />
            <Route
              path="join-us-success"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <JoinUsSuccess />
                </Suspense>
              }
            />
            <Route
              path="account/orders/:orderId/review_order/:token"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <ReviewOrderItems />
                </Suspense>
              }
            />
            <Route path="*" element={<PageNotFound />} /> 
          </Route>
          <Route element={<AuthRoutes />}>
            <Route
              path="login"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <Login />
                </Suspense>
              }
            />
            <Route
              path="signup"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <Signup />
                </Suspense>
              }
            />
            <Route
              path="forgotpassword"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <ForgotPassword />
                </Suspense>
              }
            />
            <Route
              path="mobile-otp"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <MobileOTP />
                </Suspense>
              }
            />
            <Route
              path="resetpassword"
              element={
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <ChangePassword />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="checkout"
            element={
              <Suspense
                fallback={
                  <div>
                    <Loader />
                  </div>
                }
              >
                <Checkout />
              </Suspense>
            }
          />
          <Route
            path="order-confirmation"
            element={
              <Suspense
                fallback={
                  <div>
                    <Loader />
                  </div>
                }
              >
                <OrderConfirmation />
              </Suspense>
            }
          />
          <Route path="*" element={<PageNotFound />} /> 
        </Route>
        <Route path="*" element={<PageNotFound />} /> 
      </Route>
    )
  );
};
export default AppRoutes;
