import LeftMenuBar from "../../components/LeftMenuBar";
import {
  Box,
  Container,
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CurrentLanguagePath } from "../../utils/helper";
import { Helmet } from "react-helmet";
import { useSettingStore } from "../../zustand/store/useSettingStore";

function ContactUs() {
  const { i18n, t } = useTranslation();
  const direction = i18n.dir();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const { socialLinks } = useSettingStore();

  const shareUrl =
    "https://api.whatsapp.com/send/?phone=966506515753&text&type=phone_number&app_absent=0";
  const title = "Contact Us - Neeshah";
  const shareEmailUrl = "https://www.gmail.com";

  return (
    <Box sx={{ background: "rgb(164 161 170 / 20%)" }} minHeight={"62vh"}>
      {/* <Helmet>
        <title>Contact Us</title>
      </Helmet> */}

      <Container maxWidth={"xl"}>
        <Grid
          container
          pb={8}
          sx={{
            paddingLeft: { xs: "0%", sm: "0%" },
            paddingRight: { xs: "0%", sm: "0%" },
            marginLeft: { xs: "0%", sm: "0%" },
            marginRight: { xs: "0%", sm: "0%" },
            paddingTop: { xs: "0%", sm: "2%" },
          }}
          columns={12}
        >
          <LeftMenuBar selectedItem={4} />

          <Grid item xs={12} lg={8} md={8}>
            {isSmall && (
              <Box mb={2} display={"flex"} alignItems={"center"}>
                <IconButton
                  className="rtl-transform"
                  onClick={() => navigate(CurrentLanguagePath("account"))}
                  sx={{
                    [direction === "rtl" ? "marginRight" : "marginLeft"]:
                      "-10px",
                    padding: "0px !important",
                    fontSize: "20px",
                    width: "25px",
                  }}
                >
                  <ChevronLeft />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "26.01px",
                  }}
                >
                  {t("common.overview")}
                </Typography>
              </Box>
            )}

            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "500",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {t("contactUs.heading")}
            </Typography>

            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                mt: "10px",
              }}
            >
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    width: "100%",
                    height: "225px",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: 3,
                    paddingY: "15px",
                    paddingX: "10px",
                    backgroundColor: "#fff",
                    textAlign: "center",
                    borderRadius: "8px",
                  }}
                >
                  <WhatsAppIcon fontSize="large" sx={{ mb: "25px" }} />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      mb: "20px",
                    }}
                  >
                    {t("contactUs.whatsapp.title")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      pb: "25px",
                      textAlign: "center",
                      height: "48px",
                    }}
                  >
                    {t("contactUs.whatsapp.description")}
                  </Typography>
                  {socialLinks?.whatsapp && (
                    <IconButton
                      component="a"
                      href={socialLinks?.whatsapp}
                      target="_blank"
                      rel="noreferrer"
                      size="small"
                      disableRipple
                      disableFocusRipple
                    >

                      <Button
                        variant="outlined"
                        sx={{
                          gap: "8px",
                        }}
                        endIcon={<WhatsAppIcon />}
                      >
                        {t("contactUs.whatsapp.startchat")}
                      </Button>
                    </IconButton>
                  )}
                </Box>
              </Grid>

              {/* <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    width: "100%",
                    height: "225px",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: 3,
                    backgroundColor: "#fff",
                    paddingY: "15px",
                    paddingX: "10px",
                    textAlign: "center",
                    borderRadius: "8px",
                  }}
                >
                  <PhoneIcon
                    fontSize="large"
                    sx={{ mb: "25px" }}
                    className="rtl-transform"
                  />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      mb: "20px",
                    }}
                  >
                    {t("contactUs.call.title")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      pb: "25px",
                      textAlign: "center",
                      height: "48px",
                    }}
                  >
                    {t("contactUs.call.description")}
                  </Typography>
                  {socialLinks?.contact && (
                    <EmailShareButton
                      url={socialLinks?.contact}
                      subject={title}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          gap: "8px",
                        }}
                        endIcon={<PhoneIcon className="rtl-transform" />}
                      >
                        {t("contactUs.call.callus")}
                      </Button>
                    </EmailShareButton>
                  )}
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    width: "100%",
                    height: "225px",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: 3,
                    backgroundColor: "#fff",
                    textAlign: "center",
                    paddingY: "15px",
                    paddingX: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <EmailIcon fontSize="large" sx={{ mb: "25px" }} />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      mb: "20px",
                    }}
                  >
                    {t("contactUs.email.title")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      pb: "25px",
                      textAlign: "center",
                      height: "48px",
                    }}
                  >
                    {t("contactUs.email.description")}
                  </Typography>
                  {socialLinks?.mail && (
                    <IconButton
                      component="a"
                      href={socialLinks?.mail}
                      target="_blank"
                      rel="noreferrer"
                      size="small"
                      disableRipple
                      disableFocusRipple
                    >

                      <Button
                        sx={{
                          gap: "8px",
                        }}
                        variant="outlined"
                        endIcon={<EmailIcon />}
                      >
                        {t("contactUs.email.sendemail")}
                      </Button>
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ContactUs;
