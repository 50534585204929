import {
  IconButton,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  Grid,
  Container,
  Theme,
  useTheme,
  useMediaQuery,
  Button,
  ListItemButton,
  LinearProgress,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { Close, FilterAltOutlined } from "@mui/icons-material";
import FilterPanel from "./FilterPanel";
import SlickSlider from "../../components/SlickSlider";
import ProductCard from "../../components/productCard";
import { FlexBox } from "../../utils/theme/styles/Common";
import imagepath from "../../constant/imagepath";
import { useTranslation } from "react-i18next";
import { productService } from "../../api/services/productService";
import { Products } from "../../utils/type";
import {
  Link,
  ScrollRestoration,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ShowToast from "../../utils/ShowToast";
import { wishListService } from "../../api/services/wishListService";
import useAuthStore from "../../zustand/store/user";
import { userService } from "../../api/services/userService";
import LoginDialog from "../../components/LogInDialog";
import { CurrentLanguagePath } from "../../utils/helper";
import { useLanguageStore } from "../../zustand/store/language";
import axiosInstance from "../../api/apiClient";
import { Helmet } from "react-helmet";
import { baseUrl } from "../../constant/baseUrl";
import { AddToWishlist, ViewItemList } from "../../tagManager/gtag";

interface Attribute {
  attribute_id: string;
  attribute_title: string;
  item: Item[];
}
interface Item {
  attributes_value_id: string;
  attribute_id: string;
  attribute_value: string;
}
interface SubCategory {
  id: number;
  slug: string;
  name: string;
  description: string;
}
interface SortOption {
  label: string;
  abbreviation: string;
  value: string;
}
interface Category {
  id: number;
  slug: string;
  name: string;
  description: string;
  image?: string;
}

// interface SelectedItems {
//   [key: string]: string[];
// }

// interface BuildAndNavigateParams {
//   data: string;
//   subcategory?: string;
//   selectedList?: string[];
//   selectedSubCategories?: string[];
//   discountRange: number[];
//   priceRange: number[];
// }
interface SliderItem {
  id: number;
  name: string;
  slug: string;
  src: string;
}

export interface SeoTypes {
  title: string;
  metaDescription: string;
  metaKeywords: string;
}

function ProductListing() {
  const theme: Theme = useTheme();
  const isXs: boolean = useMediaQuery(theme.breakpoints.down("lg"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "xl"));
  const toggleDrawer = () => { setDrawerOpen(!drawerOpen) };
  const { category: data, subcategory } = useParams<{
    category: string;
    subcategory?: string;
  }>();
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const { i18n, t } = useTranslation();
  const dir = i18n.dir();
  const isRTL = dir === "rtl";
  const marginProp = !isRTL ? "marginLeft" : "marginRight";
  const slug = useParams();
  const [sliderItems, setSliderItems] = useState<SliderItem[]>([]);
  const [productsData, setProductsData] = useState<Products[]>([]);
  const [seo, setSeo] = useState<SeoTypes>();
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalProducts, setTotalProducts] = useState<number>(0);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [subCategory, setSubCategory] = useState<SubCategory[]>([]);
  const [category, setCategory] = useState<Category>({} as Category);
  const [brandsData, setBrandsData] = useState<any[]>([]);
  const [selectedList, setSelectedList] = useState<any[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [priceRange, setPriceRange] = useState<number[]>([0, 0]);
  const [maxPrice, setMaxPrice] = useState<number[]>([0, 0]);
  const [discountRange, setDiscountRange] = useState<number[]>([0, 100]);
  const [searchKey, setSearchKey] = useState<string>("");
  const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isSlider, setIsSlider] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showButton, setShowButton] = useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const sortOptions: SortOption[] = [
    { label: "Relevance", abbreviation: "RV", value: "relevance" },
    { label: "Latest Arrivals", abbreviation: "NEW", value: "latest_arrivals" },
    {
      label: "Price Low to High",
      abbreviation: "LTH",
      value: "price_low_to_high",
    },
    {
      label: "Price High to Low",
      abbreviation: "HTL",
      value: "price_high_to_low",
    },
  ];

  const arraysEqual = (arr1: number[], arr2: number[]) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };

  let initialPriceRange = useRef<number[]>([0, 0]);
  const initialDiscountRange = useRef<number[]>([0, 100]);
  const navigate = useNavigate();
  const getParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const newSelectedList: any = {};
    let newSubCategories: string[] = [];
    let newPriceRange = [0, 0];
    let newDiscountRange: number[] = [0, 100];
    let sortBy = "RV";
    let searchKey = "";
    let page = "1";
    let brand: string[] = [];
    queryParams.forEach((value, key) => {
      if (key === "sub") {
        newSubCategories = value.split(",").map(String);
      } else if (key === "price") {
        newPriceRange = value.split(",").map(Number);
      } else if (key === "discount") {
        newDiscountRange = value.split(",").map(Number);
      } else if (key === "sortBy") {
        sortBy = value;
      } else if (key === "q") {
        searchKey = value;
      } else if (key === "p") {
        page = value;
      } else if (key === "b") {
        brand = value.split(",").map(String);
      }
      else {
        newSelectedList[key] = value.split(",").map((item) => item.trim());
      }
    });
    const categorySlug =
      slug.category === "slider" ||
        slug.category === "brand" ||
        slug.category === "featured" ||
        slug.category === "trending" ||
        slug.category === "search"
        ? ""
        : slug.category;
    // const subCategory =
    //   slug.category === "slider" ||
    //   slug.category === "brand" ||
    //   slug.category === "featured" ||
    //   slug.category === "trending" ||
    //   slug.category === "search"
    //     ? ""
    //     : newSubCategories;
    const subCategory = newSubCategories;
    const vendor_id = slug.category === "brand" && {
      vendor_id: slug.subcategory,
    };
    const slider_slug = slug.category === "slider" && {
      slider_slug: slug.subcategory,
    };
    const keywords = slug.category === "search" && { keywords: searchKey };
    setPriceRange(newPriceRange);
    setDiscountRange(newDiscountRange);
    setSelectedBrands(brand)
    setSelectedSubCategories(newSubCategories);
    const allIds: string[] = [];
    for (const key in newSelectedList) {
      if (Object.prototype.hasOwnProperty.call(newSelectedList, key)) {
        const values: any = newSelectedList[key as any];
        if (values.length > 0) {
          allIds.push(...values);
        }
      }
    }

    return {
      ...vendor_id,
      ...slider_slug,
      ...keywords,
      category_slug: categorySlug,
      sale_low_price: newPriceRange[0],
      sale_high_price: newPriceRange[1],
      sub_category_slugs: subCategory,
      attributes_value_id: allIds,
      sort_by: sortBy,
      discount_percentage_min: newDiscountRange[0],
      discount_percentage_max: newDiscountRange[1],
      ...(slug.category !== "brand" && { vendorIds: brand }),
      type:
        slug.category === "slider" ||
          slug.category === "brand" ||
          slug.category === "featured" ||
          slug.category === "trending" ||
          slug.category === "search"
          ? slug.category
          : "",
    };
  };

  const { lang } = useParams();
  const getValues = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const newSelectedList: any = {};
    let newSubCategories: string[] = [];
    let newPriceRange = initialPriceRange.current;
    let newDiscountRange: number[] = [0, 100];
    let sortBy = "RV";
    let searchKey = "";
    let page = "";
    queryParams.forEach((value, key) => {
      if (key === "sub") {
        newSubCategories = value.split(",").map(String);
      } else if (key === "price") {
        newPriceRange = value.split(",").map(Number);
      } else if (key === "discount") {
        newDiscountRange = value.split(",").map(Number);
      } else if (key === "sortBy") {
        sortBy = value;
      } else if (key === "q") {
        searchKey = value;
      } else if (key === "p") {
        page = value;
      } else {
        newSelectedList[key] = value.split(",").map((item) => item.trim());
      }
    });

    return {
      subCategory: newSubCategories,
      price: newPriceRange,
      discount: newDiscountRange,
      attributes_values: newSelectedList,
      sort_by: sortBy,
      page: page,
      searck_key: searchKey,
    };
  };
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const handleSelectAllBrands = () => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    const getURLData = getValues();
    const allBrandIds = brandsData.map((brand) => brand.id);
    const areAllSelected = allBrandIds.every((id) => selectedBrands.includes(id));

    if (areAllSelected) {
      setSelectedBrands([]);
      debounceRef.current = setTimeout(() => {
        buildAndNavigate(
          data,
          selectedList,
          getURLData.subCategory,
          getURLData.discount,
          getURLData.price,
          getURLData.sort_by,
          searchKey,
          1,
          []
        );
      }, 400)
    } else {
      setSelectedBrands(allBrandIds);
      debounceRef.current = setTimeout(() => {
        buildAndNavigate(
          data,
          selectedList,
          getURLData.subCategory,
          getURLData.discount,
          getURLData.price,
          getURLData.sort_by,
          searchKey,
          1,
          allBrandIds
        );
      }, 400)

    }
  };


  const handleSelectBrands = (id: string) => {
    const getURLData = getValues();
    let updatedSelectedBrands: string[] = [];
    setSelectedBrands((prevSelected) => {
      const idsArr = new Set(prevSelected);
      if (idsArr.has(id)) {
        idsArr.delete(id);
      } else {
        idsArr.add(id);
      }
      updatedSelectedBrands = Array.from(idsArr);
      return updatedSelectedBrands;
    });
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      buildAndNavigate(
        data,
        selectedList,
        getURLData.subCategory,
        getURLData.discount,
        getURLData.price,
        getURLData.sort_by,
        searchKey,
        1,
        updatedSelectedBrands
      );
    }, 400)

  };

  const fetchProducts = async (currentPage?: number) => {
    try {
      const p = searchParams.get("p");
      const pageFromUrl = currentPage ?? Number(p) ?? 1;
      let pageCount = Number(pageFromUrl);
      const request = getParams();
      let obj = {
        ...request,
        page: pageCount,
      };
      const response = await productService.getProducts(obj);
      if (response && response.status === 200) {
        setTotalPage(response.data?.total_pages);
        setTotalProducts(response.data?.total_products);
        setSeo(response.data?.seo);
        if (request.type === "featured") {
          ViewItemList({
            itemListName: "Featured Products",
            items: response.data.products.map((item: any) => ({
              productId: item.product_id,
              productName: item.product_name,
              price: item.price,
              variantId: item.product_variant_id,
              vendor: item.vendor_name,
            })),
          });
        }
        if (pageCount === 1) {
          setProductsData(response.data.products);
        } else {
          setProductsData((prevProducts) => [
            ...prevProducts,
            ...response.data.products,
          ]);
        }
        setPage(pageCount + 1);
      } else {
        console.log("Error fetching categories:", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPreviousProducts = async () => {
    try {
      const p = getValues().page || 1;
      let remainingProducts;
      let currentPage
      if (totalPage > Number(p)) {
        remainingProducts = totalProducts - productsData.length - (totalProducts - (20 * (Number(p))));
        currentPage = Math.ceil(remainingProducts / 20);
      } else {
        remainingProducts = totalProducts - productsData.length;
        currentPage = Math.ceil(remainingProducts / 20);
      }
      const request = getParams();
      let obj = {
        ...request,
        page: currentPage,
      };

      const response = await productService.getProducts(obj);
      if (response && response.status === 200) {
        setTotalPage(response.data?.total_pages);
        setTotalProducts(response.data?.total_products);
        setSeo(response.data?.seo);

        if (request.type === "featured") {
          ViewItemList({
            itemListName: "Featured Products",
            items: response.data.products.map((item: any) => ({
              productId: item.product_id,
              productName: item.product_name,
              price: item.price,
              variantId: item.product_variant_id,
              vendor: item.vendor_name,
            })),
          });
        }
        setProductsData((prevProducts) => [
          ...response.data.products,
          ...productsData,
        ]);

      } else {
        console.log("Error fetching products:", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { language, setLanguage } = useLanguageStore();

  useEffect(() => {
    fetchFilters();
  }, [location.pathname, language]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const newSelectedList: any = {};
    let newSubCategories: string[] = [];
    let newPriceRange = initialPriceRange.current;
    let newDiscountRange: number[] = [0, 100];
    let sortBy: string = "RV";
    let searchKey: string = "";
    let brand: string[] = [];

    let page = "1";
    queryParams.forEach((value, key) => {
      if (key === "sub") {
        newSubCategories = value.split(",").map(String);
      } else if (key === "price") {
        newPriceRange = value.split(",").map(Number);
      } else if (key === "discount") {
        newDiscountRange = value.split(",").map(Number);
      } else if (key === "sortBy") {
        sortBy = value as string;
      } else if (key === "q") {
        searchKey = value;
      } else if (key === "p") {
        page = value;
      } else if (key === "b") {
        brand = value.split(",").map(String);
      } else {
        newSelectedList[key] = value.split(",").map((item) => item.trim());
      }
    });
    setSelectedList(newSelectedList);
    setSearchKey(searchKey);
    buildAndNavigate(
      data,
      newSelectedList,
      newSubCategories,
      newDiscountRange,
      newPriceRange,
      sortBy,
      searchKey,
      Number(page),
      brand
    );
  }, [language]);

  function updateLanguage(languageToUse: string) {
    axiosInstance.defaults.headers["lng"] = languageToUse;
    i18n.changeLanguage(languageToUse);
    setLanguage(languageToUse);
    document.documentElement.dir = languageToUse === "ar" ? "rtl" : "ltr";
    document.body.setAttribute("dir", languageToUse === "ar" ? "rtl" : "ltr");
  }
  const buildAndNavigate = async (
    data: string | undefined,
    selectedList?: string[],
    selectedSubCategories?: string[],
    discountRange?: number[],
    priceRange?: number[],
    sortBy?: string,
    searchKey?: string,
    page?: number,
    brand?: string[]

  ) => {
    const query = new URLSearchParams();

    if (lang) {
      await updateLanguage(lang);
    }
    let url = `products`;
    if (data) {
      url += `/${data}`;
    }

    if (subcategory) {
      url += `/${subcategory}`;
    }

    if (selectedList && Object.keys(selectedList).length > 0) {
      Object.keys(selectedList).forEach((key) => {
        const values: any = selectedList[key as any];
        if (values.length > 0) {
          query.set(key, values.join(","));
        }
      });
    }

    if (selectedSubCategories && selectedSubCategories.length > 0) {
      query.set("sub", selectedSubCategories.join(","));
    }

    if (brand && brand.length > 0) {
      query.set("b", brand.join(","));
    }
    if (
      discountRange &&
      !arraysEqual(discountRange, initialDiscountRange.current)
    ) {
      query.set("discount", discountRange.join(","));
    }

    if (priceRange && !arraysEqual(priceRange, initialPriceRange.current)) {
      query.set("price", priceRange.join(","));
    }

    if (sortBy && sortBy !== "RV") {
      query.set("sortBy", sortBy);
    } else {
      query.delete("sortBy");
    }

    const pageFromUrl = page || searchParams.get("p") && Number(searchParams.get("p")) || 1;
    if (page === 1) {
      query.delete("p");
    } else if (pageFromUrl > 1) {
      query.set("p", pageFromUrl.toString());
    }

    if (searchKey) {
      query.set("q", searchKey);
    }
    const queryString = query.toString();
    if (queryString) {
      url += `?${queryString}`;
    }
    // url += `?${query.toString()}`;
    const fullUrl = CurrentLanguagePath(url);
    // window.history.pushState(null, "", fullUrl);
    navigate(CurrentLanguagePath(url), { replace: true, });
    fetchProducts(page ?? pageFromUrl);
  };
  useEffect(() => {
    setProductsData([])
    // fetchProducts(1);
  }, [language]);

  const resetUrl = () => {
    let url = `products`;
    if (data) {
      url += `/${data}`;
    }
    if (subcategory) {
      url += `/${subcategory}`;
    }
    buildAndNavigate(data);
  };

  const handlePriceChange = (event: any, newValue: number | number[]) => {
    setPriceRange(newValue as number[]);
  };

  const handleDiscountChange = (event: Event, newValue: number | number[]) => {
    setDiscountRange(newValue as number[]);
  };
  const handlePriceChangeCommit = async (event: any, newValue: number[]) => {
    const getURLData = getValues();
    buildAndNavigate(
      data,
      selectedList,
      getURLData.subCategory,
      getURLData.discount,
      newValue,
      getURLData.sort_by,
      searchKey,
      1,
      selectedBrands
    );
  };

  const handleDiscountChangeCommit = async (
    event: Event,
    newValue: number[]
  ) => {
    const getURLData = getValues();
    buildAndNavigate(
      data,
      selectedList,
      getURLData.subCategory,
      newValue,
      getURLData.price,
      getURLData.sort_by,
      searchKey,
      1,
      selectedBrands
    );
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    const abbreviation =
      sortOptions.find((option) => option.value === selectedValue)
        ?.abbreviation ?? "";

    const getUrlData = getValues();
    buildAndNavigate(
      data,
      selectedList,
      getUrlData.subCategory,
      getUrlData.discount,
      getUrlData.price,
      abbreviation,
      searchKey,
      1,
      selectedBrands
    );
  };

  const handleReset = () => {
    setSelectedList([]);
    setSelectedBrands([])
    setSelectedSubCategories([]);
    resetUrl();
  };

  const handleSelectAll = (title?: string, isSubCategory: boolean = false) => {
    let infoSelectedList = selectedList;
    let infoSubCategory = getValues().subCategory;
    if (isSubCategory) {
      const allSlugs = subCategory.map((cat: SubCategory) => cat.slug);
      const areAllSelected = allSlugs.every((slug) =>
        selectedSubCategories.includes(slug)
      );
      if (areAllSelected) {
        const filteredArray = selectedSubCategories.filter(
          (slug) => !allSlugs.includes(slug)
        );
        setSelectedSubCategories(filteredArray);
        infoSubCategory = filteredArray;
      } else {
        const updatedSelection = Array.from(
          new Set([...selectedSubCategories, ...allSlugs])
        );
        setSelectedSubCategories(updatedSelection);
        infoSubCategory = updatedSelection;
      }
    } else {
      if (title) {
        infoSelectedList = handleSelectAllAtt(title);
        setSelectedList(infoSelectedList);
      }
    }

    const getUrlData = getValues();
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      buildAndNavigate(
        data,
        infoSelectedList,
        infoSubCategory,
        getUrlData.discount,
        getUrlData.price,
        getUrlData.sort_by,
        searchKey,
        1,
        selectedBrands
      );
    }, 400)

  };

  const handleSelectAllAtt = (attribute_title: string) => {
    const attribute = attributes.find(
      (attr) => attr.attribute_title.toLowerCase() === attribute_title
    );
    if (attribute) {
      const currentSelected: any = selectedList[attribute_title as any] || [];
      const allIds = attribute.item.map((item) => item.attributes_value_id);
      const areAllSelected = allIds.every((id) =>
        currentSelected.includes(id.toString())
      );
      if (areAllSelected) {
        return {
          ...selectedList,
          [attribute_title]: [],
        };
      } else {
        return {
          ...selectedList,
          [attribute_title]: Array.from(
            new Set([...currentSelected, ...allIds])
          ),
        };
      }
    } else {
      return selectedList;
    }
  };

  const handleSubCategoryClick = async (slug: string) => {
    const newSubCategory = selectedSubCategories.includes(slug)
      ? selectedSubCategories.filter((item) => item !== slug)
      : [...selectedSubCategories, slug];
    setSelectedSubCategories(newSubCategory);
    const getUrlData = getValues();
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      buildAndNavigate(
        data,
        selectedList,
        newSubCategory,
        getUrlData.discount,
        getUrlData.price,
        getUrlData.sort_by,
        searchKey,
        1,
        selectedBrands
      );
    }, 400)
  };

  const handleItemClick = async (id: string, title: string) => {
    const key = title.toLowerCase();
    const currentSelections: string[] = selectedList[key as any] || [];
    const newSelections: string[] = currentSelections.includes(id.toString())
      ? currentSelections.filter(
        (item: string) => item.toString() !== id.toString()
      )
      : [...currentSelections, id];
    const selectedListData = {
      ...selectedList,
      [key]: newSelections,
    };
    setSelectedList(selectedListData);
    const getUrlData = getValues();
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      buildAndNavigate(
        data,
        selectedListData,
        getUrlData.subCategory,
        getUrlData.discount,
        getUrlData.price,
        getUrlData.sort_by,
        searchKey,
        1,
        selectedBrands
      );
    }, 600)

  };

  const progress =
    totalProducts > 0 ? (productsData.length / totalProducts) * 100 : 0;

  const fetchFilters = async () => {
    const vendor_id = slug.category === "brand" && {
      vendor_id: slug.subcategory,
    };
    const slider_slug = slug.category === "slider" && {
      slider_slug: slug.subcategory,
    };
    const keywords = slug.category === "search" && {
      keywords: getParams().keywords,
    };
    const slugSubCategory =
      getParams().type === "slider" ||
        getParams().type === "brand" ||
        getParams().type === "featured" ||
        getParams().type === "trending" ||
        getParams().type === "search"
        ? ""
        : getParams().sub_category_slugs[0];

    try {
      const request = {
        category_slug: getParams().category_slug,
        type: getParams().type,
        sub_category_slug: slugSubCategory,
        ...vendor_id,
        ...slider_slug,
        ...keywords,
      };
      const response = await productService.getFilters(request);
      if (response && response.status === 200) {
        setAttributes(response.data.attributes);
        setSubCategory(response.data.sub_categories);
        setCategory(response.data.category);
        setBrandsData(response.data.brands);
        if (response.data.max_price && response.data.max_price) {
          setMaxPrice([response.data.min_price, response.data.max_price]);
          initialPriceRange.current = [
            response.data.min_price,
            response.data.max_price,
          ];
        } else {
          setMaxPrice([0, 0]);
          initialPriceRange.current = [0, 0];
        }
      } else {
        console.log("Error fetching categories:", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadMore = () => {
    if (page <= totalPage) {
      const newPage = page;
      const getUrlData = getValues();
      buildAndNavigate(
        data,
        selectedList,
        getUrlData.subCategory,
        getUrlData.discount,
        getUrlData.price,
        getUrlData.sort_by,
        searchKey,
        newPage,
        selectedBrands
      );
    }
  };

  const handleLoadPrevious = () => {
    fetchPreviousProducts();
  };


  const handleLoginEmail = async (values: any) => {
    const request = {
      email: `${values.email}`.toLocaleLowerCase(),
      password: values.password,
    };

    try {
      const response: any = await userService.signIn(request);
      const { data } = response;
      if (response && response.status === 200) {
        setOpenDialog(false);
        ShowToast(response.message, "success");
        useAuthStore.getState().setUserInfo(data);
      } else {
        console.log("error message:- ", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error.message);
    }
  };

  const addWishlist = async (
    productId: string,
    variantId: string,
    product: any,
    index: number
  ) => {
    if (useAuthStore.getState().isLoggedIn === false) {
      handleClickOpen();
      return;
    }

    setIsLoading(true);

    try {
      const params = {
        product_id: productId,
        product_variant_id: variantId,
      };

      const response: any = await wishListService.addToWishList(params, { showLoader: false });

      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        setProductsData((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[index] = {
            ...updatedProducts[index],
            isAddedWishlist: true,
            wishlist_id: response.data.wishlist_id,
          };
          return updatedProducts;
        });
        AddToWishlist({
          currency: "SAR",
          value: product.price,
          items: {
            productId: product.product_id,
            productName: product.product_title,
            price: product.price,
            variantId: product.product_variant_id,
            vendor: product.vendor_name,
          },
        });
      } else {
        console.error("Error response:", response);
        ShowToast("Something went wrong", "error");
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.error("Error adding to wishlist:", error);
    } finally {
      setIsLoading(false);
      console.log('api finally')
    }
  };

  const removeWishList = async (wishListId: string, index: number) => {
    const request = { wishlist_id: wishListId };
    setIsLoading(true);
    try {
      const response: any = await wishListService.removeWishList(request, { showLoader: false });
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        const updatedProducts = [...productsData];
        updatedProducts[index] = {
          ...updatedProducts[index],
          isAddedWishlist: false,
          wishlist_id: '',
        };
        setProductsData(updatedProducts);
      } else {
        console.error("Error response:", response);
        ShowToast("Something went wrong", "error");
      }
    } catch (error: any) {
      setIsLoading(false);
      ShowToast(error.message, "error", "bottom-left");
      console.error("Error removing from wishlist:", error);
    } finally {
      setIsLoading(false);
      console.log('api finally')
    }
  };

  const getBanners = async () => {
    if (
      slug.category === "slider" ||
      slug.category === "trending" ||
      slug.category === "search"
    ) {
      setIsSlider(false);
      return;
    } else {
      const request = {
        type: "2",
        category_slug: getParams().category_slug,
        vendor_id: slug.category === "brand" ? slug.subcategory : "",
        banner_type: slug.category === "featured" ? "featured" : "",
      };
      try {
        const response = await productService.getBanner(request);
        if (response && response.status === 200) {
          if (response && !response.data.length) {
            setIsSlider(false);
          }
          setSliderItems(response.data);
        } else {
          console.log("error message:-", response);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getBanners();
  }, [data]);

  const handleScroll = () => {
    if (window.scrollY > 165) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Function to handle the browser's back button
    const handlePopState = () => {
      navigate("/");
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      // Cleanup event listener
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const subCategoryToshow = getValues()?.subCategory;

  const displayText = (() => {
    if (subCategoryToshow && subCategoryToshow.length === 1) {
      const subCategorySlug = getValues()?.subCategory?.[0];
      const matchingSubCategory = subCategory.find(
        (slue) => slue.slug === subCategorySlug
      );
      return matchingSubCategory
        ? {
          name: matchingSubCategory?.name,
          description: matchingSubCategory.description,
        }
        : null;
    }
    if (category) {
      return {
        name: category?.name,
        description: category.description,
      };
    }
    return null;
  })();

  const checkOverflow = () => {
    if (paragraphRef.current) {
      const hasOverflow =
        paragraphRef.current.scrollWidth > paragraphRef.current.clientWidth;
      setIsOverflowing(hasOverflow);
    }
  };

  const isLink = subCategoryToshow && subCategoryToshow?.length === 1;
  const Component = isLink ? Link : "p";
  const linkProps = isLink
    ? { to: CurrentLanguagePath(`products/${category?.name}`) }
    : {};

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [isMobile]);

  useEffect(() => {
    checkOverflow();
  }, [isMobile, category?.description, displayText?.description, language]);

  const p = getValues().page || 1;
  let prdPage = (Number(p) * 20) >= totalProducts ? totalProducts : (Number(p) * 20)
  const refViewport = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      let id = sessionStorage.getItem("shopPageId") ?? '';
      let element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        sessionStorage.removeItem('shopPageId');
      } else {
        window.scrollTo(0, 0);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const memoizedProductGrid = useMemo(() => {
    return productsData.map((product, index) => (
      <Grid key={index} item xs={6} sm={3} md={2} lg={3} xl={3} component={'div'} id={`#_${product.product_variant_id}`}>
        <ProductCard
          onProductClick={() => {
            const p = getValues().page || 1;
            if (Number(p) && Number(p) > 1) {
              let page = index + 1;
              let length = (Math.min(Number(p) * 20, totalProducts)) - productsData.length;
              if (length > 0) {
                page = Math.ceil((length + page) / 20);
              } else {
                page = Math.ceil(page / 20);
              }
              const queryParams = new URLSearchParams(window.location.search);
              const newSearchParams = new URLSearchParams(queryParams);
              newSearchParams.set("p", page?.toString());
              setSearchParams(newSearchParams);
              sessionStorage.setItem("shopPageId", `#_${product.product_variant_id}`);
            } else {
              sessionStorage.setItem("shopPageId", `#_${product.product_variant_id}`);
            }
          }}
          data={product}
          minWidth={"auto"}
          handleWishlist={() => {
            console.log(isLoading, 'isLoadingisLoading')
            if (!isLoading) {
              if (product.isAddedWishlist) {
                removeWishList(product.wishlist_id, index);
              } else {
                addWishlist(
                  product.product_id.toString(),
                  product.product_variant_id.toString(),
                  product,
                  index
                );
              }
            }
          }}
        />
      </Grid>
    ));
  }, [productsData, totalProducts, addWishlist, removeWishList]);

  return (
    <>
      <div style={{ minHeight: "100vh" }} ref={refViewport}>
        {isSlider && (
          <Container maxWidth={"lg"}>
            <SlickSlider sliderItems={sliderItems} height="290px" />
          </Container>
        )}
        <Box sx={{}}>
          {isXs && (
            <>
              {category?.image && (
                <img
                  src={category?.image}
                  alt={category?.name}
                  style={{
                    width: "63px",
                    height: "100%",
                    borderRadius: "50%",
                    border: "1px solid #000",
                    display: "block",
                    margin: "0 auto",
                    marginTop: "8px",
                  }}
                />
              )}
              {category?.name && (
                <Typography
                  component={Component}
                  {...linkProps}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "26.01px",
                    textAlign: "center",
                    mt: 1,
                    display: "block",
                    textDecoration: "none",
                  }}
                >
                  {category?.name}
                </Typography>
              )}

              {getValues().subCategory &&
                getValues().subCategory?.length === 1 &&
                displayText?.name && (
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "26.01px",
                      textAlign: "center",
                      mt: 1,
                      display: "block",
                      textDecoration: "none",
                      textTransform: "capitalize",
                      wordBreak: "normal",
                      overflowX: "hidden",
                      overflowY: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {displayText?.name}
                  </Typography>
                )}
              {displayText && displayText?.description && (
                <p
                  style={{
                    display: "flex",
                    padding: "0 10px",
                    marginBottom: "0",
                  }}
                  onLoad={checkOverflow}
                >
                  <p
                    ref={paragraphRef}
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      lineHeight: "1.4",
                      textAlign: "left",
                      wordWrap: "normal",
                      display: "block",
                      height: "auto",
                      marginTop: "3px",
                      ...(!isExpand && {
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-all",
                        whiteSpace: "nowrap",
                      }),
                    }}
                  >
                    {displayText.description}
                  </p>
                  {isOverflowing && (
                    <IconButton
                      onClick={() => setIsExpand(!isExpand)}
                      sx={{ padding: "0" }}
                    >
                      <img
                        src={imagepath.down}
                        alt="Toggle Expand"
                        style={{
                          width: "20px",
                          height: "20px",
                          transform: isExpand ? "rotate(180deg)" : "",
                        }}
                      />
                    </IconButton>
                  )}
                </p>
              )}
              <List
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 1,
                  flexWrap: "nowrap",
                  overflowX: "auto",
                  [isRTL ? "paddingLeft" : "paddingRight"]: "10px",
                }}
              >
                {subCategory &&
                  subCategory.map((item: SubCategory, idx: number) => (
                    <ListItem
                      disableGutters
                      key={idx}
                      sx={{
                        width: "auto",
                        border: "1px solid #000",
                        [marginProp]: "10px",
                        mb: 1,
                        height: "auto",
                        whiteSpace: 'nowrap',
                        backgroundColor:
                          selectedSubCategories &&
                            selectedSubCategories.includes(item.slug)
                            ? "#eaebed"
                            : "#fff",
                        padding: "0 5px 0 5px",
                      }}
                    >
                      <ListItemButton
                        style={{ height: "35px" }}
                        onClick={() => {
                          handleSubCategoryClick(item.slug.toString());
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            display: "flex",
                            flexWrap: "wrap",
                            lineHeight: "14px",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </>
          )}
          <Container
            maxWidth={"xl"}
            sx={{
              [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
                paddingRight: "5px",
                paddingLeft: "5px",
              },
            }}
          >
            <Box component="main" sx={{ flexGrow: 1 }}>
              <Grid
                container
                sx={{ mt: isXs ? 0 : "10px" }}
                spacing={isXs ? 0 : 2}
              >
                {!isXs && (
                  <Grid item xs={12} sm={2.5} md={2.5}>
                    {slug.category !== "brand" && category?.name && (
                      <FlexBox sx={{ mt: "12px", mb: 0 }}>
                        <Link
                          to={CurrentLanguagePath("")}
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#A4A1AA",
                            textDecoration: "none",
                          }}
                        >
                          {t("products.shop")}
                        </Link>
                        <Typography
                          component={Component}
                          {...linkProps}
                          fontSize={"16px"}
                          fontWeight={"500"}
                          color="#000"
                          sx={{
                            textDecoration: "none",
                            wordBreak: "normal",
                            overflowX: "hidden",
                            overflowY: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            display: "block",
                          }}
                        >
                          &nbsp; - {category?.name}
                        </Typography>
                        {getValues().subCategory &&
                          getValues().subCategory?.length === 1 &&
                          displayText && displayText?.name && (
                            <Typography
                              sx={{
                                display: "block",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "26.01px",
                                textAlign: "center",
                                textTransform: "capitalize",
                                wordBreak: "normal",
                                overflowX: "hidden",
                                overflowY: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              &nbsp; - {displayText.name}
                            </Typography>
                          )}
                      </FlexBox>
                    )}
                    {slug.category !== "brand" &&
                      displayText &&
                      displayText?.description && (
                        <Typography
                          color={"#000000"}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "300",
                            lineHeight: "20.23px",
                            mt: "4px",
                          }}
                        >
                          {displayText.description}
                        </Typography>
                      )}
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={7.5}
                  xl={7.5}
                  sx={{ p: 0 }}
                >
                  <Box>
                    {!isXs && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            mb: slug.category === "brand" ? 2 : 0,
                          }}
                        >
                          {category?.image && (
                            <img
                              src={category?.image}
                              alt={category?.name}
                              style={{
                                borderRadius: "50px",
                                height: "100px",
                                border: "1px solid black",
                                width: "100px",
                                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                              }}
                            />
                          )}
                          <Box sx={{ mx: slug.category === "brand" ? 2 : 0 }}>
                            {category?.name && (
                              <Typography
                                sx={{
                                  fontSize: "40px",
                                  fontWeight: "500",
                                  lineHeight: "26.01px",
                                  mt: 1,
                                  pb: "13px",
                                  textDecoration: "none",
                                  textAlign: "center",
                                }}
                              >
                                {category?.name}
                              </Typography>
                            )}
                            {slug.category === "brand" &&
                              category &&
                              category.description && (
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "300",
                                    lineHeight: "20.23px",
                                  }}
                                >
                                  {category.description}
                                </Typography>
                              )}
                          </Box>
                        </Box>

                        {searchKey && (
                          <Typography
                            variant="h6"
                            sx={{
                              flexWrap: "wrap",
                              wordWrap: "break-word",
                            }}
                          >
                            {t("products.for", { query: searchKey })}
                          </Typography>
                        )}

                        <List
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            flexWrap: "wrap",
                          }}
                        >
                          {subCategory.map((item: SubCategory, idx: number) => (
                            <ListItem
                              disableGutters
                              key={`${item}-${idx}`}
                              sx={{
                                width: "auto",
                                border: "1px solid #000",
                                mr: 1,
                                mb: 1,
                                height: "35px",
                                backgroundColor: selectedSubCategories &&
                                  selectedSubCategories.includes(item.slug)
                                  ? "#eaebed"
                                  : "#fff",
                              }}
                            >
                              <ListItemButton
                                style={{ height: "35px" }}
                                onClick={() => {
                                  handleSubCategoryClick(item.slug.toString());
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    lineHeight: "14px",
                                    textAlign: "center",
                                    fontWeight: "300",
                                  }}
                                >
                                  {item?.name}
                                </Typography>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                        {!useAuthStore.getState().isLoading && productsData.length > 0 && (
                          <Box
                            sx={{
                              display: "block",
                              margin: "auto"
                            }}
                          >
                            {prdPage !== productsData.length && (
                              <Button
                                fullWidth
                                variant="outlined"
                                sx={{
                                  height: 60,
                                  maxWidth: "300px",
                                  background: "#fff",
                                  color: "#000",
                                  fontSize: "16px",
                                  fontWeight: "500 !important",
                                  borderRadius: 0,
                                  borderWidth: 2,
                                  borderColor: "rgb(233, 233, 233)",
                                  mb: "30px",
                                  "&:hover": {
                                    borderColor: "rgb(233, 233, 233)",
                                  },
                                  display: "block",
                                  margin: "auto",
                                  textAlign: "center"
                                }}
                                onClick={handleLoadPrevious}
                              >
                                {t("products.previous")}
                              </Button>
                            )}
                          </Box>
                        )}

                      </>
                    )}
                  </Box>

                  {isXs && (
                    <>
                      <Box
                        sx={{
                          marginTop: "16px"
                        }}
                      >
                        {prdPage !== productsData.length && (
                          <Button
                            fullWidth
                            variant="outlined"
                            sx={{
                              height: 60,
                              maxWidth: "300px",
                              background: "#fff",
                              color: "#000",
                              fontSize: "16px",
                              fontWeight: "500 !important",
                              borderRadius: 0,
                              borderWidth: 2,
                              borderColor: "rgb(233, 233, 233)",
                              mb: "30px",
                              "&:hover": {
                                borderColor: "rgb(233, 233, 233)",
                              },
                              display: "block",
                              margin: "auto",
                              textAlign: "center"
                            }}
                            onClick={handleLoadPrevious}
                          >
                            {t("products.previous")}
                          </Button>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          width: "100%",
                        }}
                      >
                        <Box
                          component={"a"}
                          onClick={toggleDrawer}
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            lineHeight: "23.12px",
                            textDecoration: "none",
                            color: "#000",
                            mr: "10px",
                          }}
                        >
                          {t("products.filter")}
                        </Box>


                        {showButton && (
                          <Box
                            sx={{
                              position: "fixed",
                              bottom: "20px",
                              zIndex: 2,
                              fontSize: "18px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "#000",
                              color: "white",
                              cursor: "pointer",
                              padding: "10px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow: 3,
                              borderRadius: "50%",
                              mx: "5px",
                            }}
                            onClick={toggleDrawer}
                          >
                            <FilterAltOutlined
                              style={{
                                width: "25px",
                                height: "25px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 2 }} spacing={isXs ? 0 : 2}>
                <Grid
                  item
                  xs={12}
                  sm={2.5}
                  md={2.5}
                  sx={{ pt: "5px !important" }}
                >
                  {!isXs && (
                    <FilterPanel
                      brands={brandsData}
                      selectedBrand={selectedBrands}
                      attributes={attributes}
                      subCategory={subCategory}
                      sortOptions={sortOptions}
                      selectedList={selectedList}
                      selectedSort={getValues().sort_by}
                      selectedSubCategory={selectedSubCategories}
                      priceRange={priceRange}
                      discountRange={discountRange}
                      onReset={handleReset}
                      onSelectAll={handleSelectAll}
                      onSubCategoryClick={handleSubCategoryClick}
                      onItemClick={handleItemClick}
                      onPriceChange={handlePriceChange}
                      onDiscountChange={handleDiscountChange}
                      onSortChange={handleSortChange}
                      onPriceChangeCommit={handlePriceChangeCommit}
                      onDiscountCommit={handleDiscountChangeCommit}
                      maxPriceRange={maxPrice}
                      selectAllBrand={handleSelectAllBrands}
                      onSelectBrand={handleSelectBrands}

                    />
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={7.5}
                  xl={7.5}
                  sx={{ pt: "5px !important" }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "300",
                        lineHeight: "20.23px",
                        borderBottom: "2px solid #f5f5f5",
                        margin: "8px 10px",
                        width: "calc(100% - 20px)",
                        display: "flex",
                      }}
                    >
                      {totalProducts} {`${t("products.found")}`}
                    </Typography>

                  </Box>
                  <Grid container sx={{ paddingLeft: "0px" }}>
                    {memoizedProductGrid}
                  </Grid>
                  {productsData.length > 0 && (
                    <Box sx={{ textAlign: "center", mt: 4 }}>
                      <Typography
                        sx={{ fontSize: "14px", fontWeight: "300", mb: 1 }}
                        gutterBottom
                      >
                        {`${t("products.viewed")} ${productsData.length} ${t(
                          "products.of"
                        )} ${totalProducts} ${t("products.styles")}`}
                      </Typography>
                      <Box sx={{ width: "20%", margin: "0 auto", mb: 2 }}>
                        <LinearProgress
                          className="rtl-transform"
                          variant="determinate"
                          value={progress}
                          sx={{
                            background: "rgb(233, 233, 233)",
                            "& .MuiLinearProgress-barColorPrimary": {
                              backgroundColor: "#2268cb",
                            },
                            "& .MuiLinearProgress-barColorSecondary": {
                              backgroundColor: "rgb(233, 233, 233)",
                            },
                          }}
                        />
                      </Box>
                      {page <= totalPage && productsData.length > 0 && (
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={handleLoadMore}
                          sx={{
                            height: 60,
                            maxWidth: "300px",
                            background: "#fff",
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "500 !important",
                            borderRadius: 0,
                            borderWidth: 2,
                            borderColor: "rgb(233, 233, 233)",
                            mb: "30px",
                            "&:hover": {
                              borderColor: "rgb(233, 233, 233)",
                            },
                          }}
                        >
                          {t("products.load_more")}
                        </Button>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box >
        {isXs && (
          <Drawer
            anchor={dir === "rtl" ? "left" : "right"}
            open={drawerOpen}
            onClose={toggleDrawer}
            PaperProps={{
              sx: {
                scrollbarGutter: "stable",
                overflowY: "auto",
                width: isMobile ? "100%" : isTablet ? "auto" : "30%",
                height: isMobile ? "100%" : isTablet ? "auto" : "auto",
                paddingLeft: "0px",
                display: "flex",
                border: "2px solid #f0f0f0",
                ...(isTablet && {
                  width: 400,
                  height: 580,
                  border: "2px solid #f0f0f0",
                  left: "50%",
                  marginLeft: "-200px",
                  marginTop: "-290px",
                  maxHeight: 580,
                  overflow: "hidden",
                  top: "50%",
                  zIndex: 6000,
                }),
              },
            }}
          >
            <Box sx={{ position: "relative" }}>
              <IconButton
                onClick={toggleDrawer}
                sx={{
                  position: "absolute",
                  top: 0,
                  [dir === "rtl" ? "left" : "right"]: 0,
                }}
                size="large"
              >
                <Close />
              </IconButton>
            </Box>
            <Container maxWidth="lg" sx={{ pb: "20px" }}>
              <FilterPanel
                maxPriceRange={maxPrice}
                subCategory={subCategory}
                brands={brandsData}
                selectedBrand={selectedBrands}
                sortOptions={sortOptions}
                selectedList={selectedList}
                selectedSort={getValues().sort_by}
                attributes={attributes}
                selectedSubCategory={selectedSubCategories}
                priceRange={priceRange}
                discountRange={discountRange}
                onReset={handleReset}
                onSelectAll={handleSelectAll}
                onSubCategoryClick={handleSubCategoryClick}
                onItemClick={handleItemClick}
                onPriceChange={handlePriceChange}
                onDiscountChange={handleDiscountChange}
                onSortChange={handleSortChange}
                onPriceChangeCommit={handlePriceChangeCommit}
                onDiscountCommit={handleDiscountChangeCommit}
                selectAllBrand={handleSelectAllBrands}
                onSelectBrand={handleSelectBrands}
              />
            </Container>
          </Drawer>
        )
        }
        <LoginDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          handleLogin={handleLoginEmail}
        />
      </div >

    </>
  );
}

export default ProductListing;
