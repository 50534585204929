import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ArrowBackIos, ArrowForwardIosOutlined } from "@mui/icons-material";
import { Container, Typography, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import { CurrentLanguagePath } from "../utils/helper";
import { useLanguageStore } from "../zustand/store/language";

interface Brand {
  id: number;
  slug: string;
  url: string;
}

interface ProductSliderProps {
  brands: Brand[];
  onBrandClick?: (item: Brand) => void;
}

const ProductSlider: React.FC<ProductSliderProps> = ({
  brands,
  onBrandClick,
}) => {
  const { i18n, t } = useTranslation();
  const dir = i18n.dir();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [clientXonMouseDown, setClientXonMouseDown] = React.useState<
    number | null
  >(null);
  const [clientYonMouseDown, setClientYonMouseDown] = React.useState<
    number | null
  >(null);
  const handleOnMouseDown = (e: React.MouseEvent) => {
    setClientXonMouseDown(e.clientX);
    setClientYonMouseDown(e.clientY);
    e.preventDefault();
  };

  const handleOnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      e.preventDefault();
    }
  };
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
    // const {
    //   carouselState: { currentSlide },
    // } = rest;
    return (
      <div
        className="carousel-button-group mb-4  gap-4 flex justify-end 
        items-center w-full"
      >
        <IconButton
          onClick={() => previous()}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          sx={{
            [dir === "rtl" ? "right" : "left"]: "-5px",
            position: "absolute",
            top: "50%",
            zIndex: 10,
            padding: "0px !important",
            color: "#000 !important",
            display: { xs: "none", sm: "block", lg: "block" },
            "& .css-1j3sv1k-MuiSvgIcon-root": {
              fill: "#000 !important",
              color: "#000 !important",
            },
          }}
        >
          <ArrowBackIos className="rtl-transform" />
        </IconButton>
        <IconButton
          onClick={() => next()}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          sx={{
            [dir === "ltr" ? "right" : "left"]: "-5px",
            position: "absolute",
            top: "50%",
            zIndex: 10,
            padding: "0px !important",
            color: "#000 !important",
            display: { xs: "none", sm: "block", lg: "block" },
            "& .css-1j3sv1k-MuiSvgIcon-root": {
              fill: "#000 !important",
              color: "#000 !important",
            },
          }}
        >
          <ArrowForwardIosOutlined className="rtl-transform" />
        </IconButton>
      </div>
    );
  };
  const { language } = useLanguageStore();
  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: isMobile ? 1 : "0 0px",
        margin: "0 auto",
        position: "relative",
      }}
    >
      <Typography fontSize="24px" fontWeight="500" ml={1} mb={1}>
        {t("home.mostSearchedBrands")}
      </Typography>
      <div dir={dir}>
        {!isMobile ? (
          <Carousel
            infinite
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 8,
                // paritialVisibilityGutter: 60
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 5,
                // paritialVisibilityGutter: 50
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2,
                // paritialVisibilityGutter: 30
              },
            }}
            rtl={language === "ar"}
            ssr
            deviceType={"desktop"}
            itemClass="image-item"
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup />}
          >
            {brands.map((brand, index) => {
              return (
                <Link
                  key={index}
                  to={CurrentLanguagePath(`products/brand/${brand.id}`)}
                  onMouseDown={handleOnMouseDown}
                  onClick={handleOnClick}
                  draggable={false}
                >
                  <Box
                    key={index}
                    sx={{
                      padding: "10px 10px 5px 10px",
                      textAlign: "center",
                      mb: 0,
                    }}
                  >
                    <img
                      src={brand.url}
                      alt={brand.slug}
                      style={{ width: "95px", height: "83px" }}
                    />
                  </Box>
                </Link>
              );
            })}
          </Carousel>
        ) : (
          <Grid
            container
            className="brand-list"
            wrap="nowrap"
            sx={{ p: 0, overflowX: "auto" }}
          >
            {brands.map((brand, index) => (
              <Grid key={index} item>
                <Link to={CurrentLanguagePath(`products/brand/${brand.id}`)}>
                  <Box
                    // onClick={() => onBrandClick && onBrandClick(brand)}
                    sx={{
                      padding: "10px 10px 5px 10px",
                      textAlign: "center",
                      overflow: "hidden",
                      mb: 0,
                    }}
                  >
                    <img
                      src={brand.url}
                      alt={brand.slug}
                      style={{ width: "95px", height: "83px" }}
                    />
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </Container>
  );
};

export default ProductSlider;
